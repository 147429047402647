@import '../../global.scss';

.wrapper {
  position: relative;
}

.searchResults {
  position: absolute;
  left: 0;
  width: 100%;
  background: $otcWhite;
  border: 1px solid $otcGreen;
  border-top: none;
  z-index: 999;
  text-align: left;

  .searchResultItem {
    text-align: left;
    padding: 0.5rem 0.8rem 0.4rem 0.8rem;
    color: $otcDarkGray;
    font-weight: $fontWeightBold;
    font-size: 1rem;
    cursor: pointer;
    display: flex;

    &.activeItem {
      background-color: $otcLightGray;
    }

    &.searchResultItemHidden {
      display: none;
    }

    .symbolTierContainer {
      display: inline-flex;
      min-width: 2.6rem;
      text-align: center;
      align-items: center;
      height: 1.2rem;
      vertical-align: top;

      span {
        font-weight: 500;
      }

      img {
        max-width: 1.4rem;
        max-height: 1.25rem;
        padding-right: 1rem;
        position: relative;
        bottom: 1px;
      }
    }

    .name {
      line-height: 1.2rem;
      flex: 1 1 auto;
    }
  }

  .searchInfo {
    padding: 0.4em 0.8em;
    color: $otcDarkGray;
  }

  &.searchResultsHidden {
    display: none;
  }
}
