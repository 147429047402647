@import '../../global.scss';

.accordion {
  .item {
    padding: .88em 0 0 0;

    .subtitle {
      font-size: 0.8rem;
      color: $otcGray;
      font-weight: lighter;
      display: inline-block;
    }

    .columnView {
      &.subtitle {
        margin-left: 0;
      }
    }

    .content {
      padding: 0.7em 0;
      display: none;
    }

    .title {
      font-size: 0.9em;
      border-bottom: 1px solid $otcLightGray;
      padding-bottom: .78em;
      position: relative;
      b {
        padding-right: 1.4em;
      }
      a {
        text-decoration: none;
        color: $otcDarkGray;
      }
      &.hasContent {
        b {
          cursor: pointer;
        }
      }
    }

    .visible {
      display: block;
      border-bottom: 1px solid $otcLightGray;
    }

    .chevron {
      font-size: 0.69em;
      font-weight: lighter;
      position: absolute;
      top: 0.2em;
      cursor: pointer;
      transition: color 200ms;
      &:hover {
        color: $otcGreen;
      }
    }

    &.compact {
      padding: .8em 0 0 0;
      .title {
        padding-bottom: .64em;
      }
    }

    &.fullWidth {
      .title b {
        padding-right: 2em;
        display: inline-block;
      }
      .chevron {
        right: .1em;
      }
    }
  }

  .link {
    // @include link-colors;

    &:hover {
      color: $otcGreen !important;
    }
  }
}
