@import '../../global.scss';

.tableContainer {
  max-width: 100%;

  @include sm {
    overflow-x: auto;	
    overflow-y: hidden;
  }
}

.table {
  font-size: .875rem;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;

  &.overline {
    border-top: $dividerGreen;
  }

  &.thickOverline {
    border-top: $dividerThick;
  }

  // links should always inherit cell color
  a { color: inherit; }
}
.largeFont {
  font-size: 1rem;
}

.cell {
  text-align: left;
  font-weight: $fontWeightRegular;
  border-bottom: $dividerGray;
  margin: 0;
  line-height: 1.22em;
  min-height: 1.22em;
  vertical-align: top;
  padding: .5em .85em;
  &:first-child { padding-left: 0; }
  &:last-child { padding-right: 0; }
  &.clickable {
    cursor: pointer;
  }

  &.extendedCell {
    border-bottom: none;
    padding: 0;
  }

  * {
    line-height: inherit;
    min-height: auto;
  }
}

.ContentTable tr:first-child td, th.cell {
  color: #999;
  font-weight: $fontWeightBold;
  text-transform: uppercase;
  font-size: .85em;
  line-height: 1.1em;
  padding-top: .7em;
  padding-bottom: .61em;
}

.bold {
  font-weight: $fontWeightBold;
}

.numberIcon {
  border-radius: 50%;
  border: 1px solid $otcGreen;
  color: $otcGreen;
  line-height: 1.8em;
  width: 1.8em;
  height: 1.8em;
  display: inline-block;
  font-size: .65em;
  text-align: center;
  font-weight: $fontWeightRegular;
  box-sizing: border-box;
}

.link {
  color: $otcDarkGray;
  &:hover { color: $otcGreen; }

  // @include link-colors;
}

// alignment
.left { text-align: left; }
.right { text-align: right; }
.center { text-align: center; }

.noWrap {
  white-space: nowrap;
}

// responsive styles
.hideOnMobile {
  @include sm {
    display: none;
  }
}

.hideOnTablet {
  @include md {
    display: none;
  }
}

.sortDirection {
  color: $otcGray;
  padding-left: 1.6em;
  position: relative;
  svg {
    font-size: 1.3em;
    position: absolute;
    left: .85em;
    bottom: .1em;
  }
}

.tick {
  font-size: 1.3em;
  position: relative;
  bottom: .1em;
}

.expandChevron {
  font-size: .7em;
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: $otcGreen;
  }
}

.green {
    color: limegreen;
}

.red {
    color: red;
}