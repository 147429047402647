@import "../../global.scss";

.app {
  font-family: $otcFontPrimary, sans-serif;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  h1 {
    @include h1;
  }
  h2 {
    @include h2;
  }
  h3 {
    @include h3;
  }
  min-height: 100vh;
  background-color: $otcWhite;
  color: $otcDarkGray;

  &.appStyleguide {
    min-height: 0;
  }
}
.app * {
  font-family: inherit;
  line-height: 1.5em;
}

.wrapper {
  max-width: $max-width;
  padding: 0 1em;
}

.preloginWrapper {
  background-color: $otcBlack;
}

.subHeader {
  padding: 1.5em 0;
}

.stockScreener {
  @include sm {
    margin-top: 1em;
  }
  @include md-lg {
    padding-left: 2em;
  }
}

.marketsTotals {
  @include sm-md {
    display: none;
  }
}
