@import '../../global.scss';

$headerBorderWidth: 3px;

$headerHeightMobile: 54px;

.header {
  align-self: stretch;
  background-color: $otcBlack;
  color: $otcWhite;
  position: relative;
 
  @include lg {
    padding: 15px 0 0;
  }
  @include sm-md {
    min-height: $headerHeightMobile;
  }
}

.headerLineContainer {
  align-self: stretch;
  z-index: 999;
}

.menuHolder {
  padding-bottom: 2.3em;
  @include sm-md {
    padding: .6em 1em 2em 1em;
    display: none;
    &.open {
      border-top: 1px solid $otcWhite;
      display: block;
    }
  }
}

.env {
  font-size: 20px;
  font-weight: 100;
  text-transform: uppercase;
  top: 25%;
  position: absolute;
}
.otcLine {
  width: 100%;
  height: $headerBorderWidth;
  background-color: $otcOrange;
  position: relative;
  bottom: 0;
  overflow: hidden;

  &:after {
    content: '';
    background: $otcGreen;
    position: absolute;
    width: 33.33%;
    left: 0;
    bottom: 0;
    height: $headerBorderWidth;
    transform: skew(-24deg,0);
  }
  &:before {
    content: '';
    background: $otcPink;
    position: absolute;
    width: 33.33%;
    right: 0;
    bottom: 0;
    height: $headerBorderWidth;
    transform: skew(-24deg,0);
  }
}

.container {
  position: relative;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 1em;
  @include sm-md {
    padding: 0;
  }
}

.headerTop, .searchBar {
  transition: opacity 300ms;
}

.headerTop {
  position: relative;
  z-index: 1001;
  &.searchOpen {
    opacity: 0;
    z-index: 1000;
    pointer-events: none;
  }
}

.searchBar {
  position: absolute;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  @include lg {
    padding-right: 2em;
  }
  z-index: 1000;
  &.searchOpen {
    opacity: 1;
    z-index: 1001;
    pointer-events: all;
  }

  @include sm-md {bottom: 0;
    padding: .65em .6em;
    top: .1em;
    bottom: 0;
  }

  form {
    position: relative;
    width: 100%;
    height: 100%;
  }

  input {
    height: 100%;
    width: 100%;
    background: transparent;
    outline: none;
    color: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
    font-size: 1.4em;

    @include placeholder {
      color: #aaa !important;
      font-weight: lighter !important;
      font-style: italic !important;
    }

    @include sm-md {
      font-size: 1.1em;
      border: none;
    }
  }

  .close {
    position: absolute;
    right: 0;
    bottom: 0;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    width: auto;
    height: 100%;
    font-size: 1.6em;
    opacity: .4;
    transition: opacity 200ms;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }

    @include sm-md {
      padding-bottom: .2em;
      font-size: 1.5em;
    }
  }
}

.loggedoutMenu {
  padding-bottom: 5px;
}

.loginCta {
  margin-left: 10px;
}

.headerItem {
  cursor: pointer;
  display: inline-block;
  color: $otcWhite;
  text-decoration: none;
  transition: color 200ms;
  &:hover {
    color: $otcGreen;
  }
  a {
    display: block;
    color: inherit;
    text-decoration: inherit;
  }
  @include lg {
    // margin-left: 2em;
    
  }
  @include sm-md {
    font-size: 1.3em;
    position: relative;
    width: $headerHeightMobile;
    height: $headerHeightMobile;
    line-height: $headerHeightMobile;
    text-align: center;
    border-left: 1px solid $otcLightGray;
    border-right: 1px solid $otcLightGray;
    margin-right: -1px;
    z-index: 1000;
    transition: all 200ms;
    
    &.active {
      border-left: 1px solid $otcGreen;
      border-right: 1px solid $otcGreen;
      background: $otcGreen;
      z-index: 1001;
      color: #fff;
    }
  }
}

.textLink {
  display: inline-block;
  color: $otcWhite;
  text-decoration: none;
  margin-right: 30px;

  @include sm-md {
    display: none;
  }
}

.logo {
  img {
    height: 4.2em;
    padding: 5px 0;
    box-sizing: border-box;
  }
  @include sm-md {
    margin-left: 1em;
    height: $headerHeightMobile;
    display: flex;
    align-items: center;
    a, img {
      display: inline-block;
      height: 3em;
    }
  }
}

.topRightLinks {
  display: flex;
}

.topRightHeaderLinks {
  display: flex;
  padding-top: 15px; 
}
/* Symbol Search Results */

.searchResults {
  background: #eee;
}
.searchContainer {
  box-sizing: border-box;
  padding-top: .8em;
  padding-bottom: .8em;
  @include sm-md {
    padding: .8em !important;
  }
}
.companyName {
  color: #777;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.searchMessage {
  font-style: italic;
  border-top: 1px solid #aaa;
  margin-top: .8em;
  padding-top: .8em;
  color: #666;
  a {
    color: #666 !important;
    cursor: pointer;
  }
}
.searchSubheader {
  text-transform: uppercase;
  font-weight: bold;
  color: #999;
  font-size: .9rem;
  margin-bottom: .5em;
}
