@import "../../global.scss";

.link-default {
  @include link;
}
.link-default-orange {
  &:hover { color: $otcOrange; }
}
.link-default-pink {
  &:hover { color: $otcPink; }
}

.link-block {
  @include link;
  display: inline-block;
  padding: .3em 0;
  padding-right: 1em;
  margin-right: .5em;
  position: relative;

  span {
    white-space: nowrap;
  }

  svg {
    font-size: .6em;
    margin-left: .6rem;
    position: relative;
    bottom: .05rem;
    color: $otcGreen;
  }

}
.link-block-orange {
  &:hover { color: $otcOrange; }
  svg { color: $otcOrange; }
}
.link-block-pink {
  &:hover { color: $otcPink; }
  svg { -color: $otcPink; }
}

.link-file {
  @include link;
  display: inline-block;
  padding: .3em 0;
  padding-left: 1.4em;
  position: relative;

  &:before {
    text-decoration: none !important;
    content: ' ';
    position: absolute;
    background-image: url('../../../public/icons/file.png');
    background-position: 0 0;
    background-size: .86em;
    background-repeat: no-repeat;
    left: 0;
    top: .6em;
    width: 1em;
    height: 1em;
  }
}

.link-button {
  @include button;
  @include sm {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }
}
.link-button-orange {
  color: $otcOrange;
  border-color: $otcOrange;
  &:hover { background: $otcOrange; }
}
.link-button-pink {
  color: $otcPink;
  border-color: $otcPink;
  &:hover { background: $otcPink; }
}

.link-heading {
  @include link;
  text-decoration: none;
  &:hover { text-decoration: underline; }
}
.link-heading-orange {
  &:hover { color: $otcOrange; }
}
.link-heading-pink {
  &:hover { color: $otcPink; }
}
