@import '../../../../global.scss';

.component {
  &:last-child {
    border-bottom: 0;
  }
}

.header {
  display: flex;
  padding: 1em 0;
  border-bottom: 1px solid $otcLightGray;
}

.headerInfo {
}

.name {
  font-size: 1.1em;
  font-weight: bold;
  a { text-decoration: none; }
  a:hover { text-decoration: underline; }
}

.info {
  display: flex;
  border-bottom: 1px solid $otcGreen;
}

.infoPrimary {
  padding: 1em 0;
  border-bottom: 1px solid $otcLightGray;
}

.infoItem {
  @include md-lg {
    padding: 1em 1.5em;
    border-left: 1px solid $otcLightGray;
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
  }
  @include sm {
    padding: 1em 1.5em;
    padding-left: 0;
    border-top: 1px solid $otcLightGray;
    &:first-child {
      border-top: none;
    }
  }
}

.description {
  p {
    display: inline;
    font-family: $otcFontSecondary;
    color: $otcDarkGray;
    font-weight: lighter;
  }
}

.descriptionReadMore {
  display: inline-block;
  margin-left: 16px;
  color: $otcGray;
}
