@import '../../global.scss';

.MAIndicesPage {
  p {
    margin: 0;
  }
}

.snapshot {
  margin-top: 2em;
  position: relative;
}

.mdLeft {
  margin-right: 3em;
}

.mdRight {
  margin-left: 3em;
  height: fit-content;
}

.linkList {
  padding: 1em 0;
  a {
    color: black;
  }
  border-bottom: 1px solid $otcLightGray;
}

.footerNote {
  font-size: 0.7em;
  font-weight: $fontWeightLight;
  color: $otcGray;
  display: block;
  margin-top: 1em;
  margin-bottom: 3em;
}

.sideColumn {
  @include sideColumn;
}