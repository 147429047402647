@import '../../global.scss';

.input {
  position: relative;
  outline: none;
  width: 100%;
  min-width: 120px;
  display: flex;
  transition: all 150ms;

  > input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 1em;
    font-family: inherit;
  }

  > label {
    transition: all 200ms;
    flex: 1;
    cursor: pointer;
  }

  > .value {
    flex: 1;
    background-color: $otcBackground;
  }

  &.default {
    margin: 2em 0 3em 0;
    border-bottom: 1px solid $otcGray;

    &.focused {
      border-color: $otcGreen;
      > label {
        color: $otcGreen;
      }
    }

    &.inactive {
      border-color: $otcLightGray;
      > label {
        color: $otcLightGray;
      }
    }

    &.error {
      border-color: $otcErrorColor;
    }

    > input {
      padding: .3em;
      padding-left: 0;
      height: 1.2em;
      line-height: 1.2em;
    }

    > label {
      color: $otcGray;
      position: absolute;
      pointer-events: none;
    }

    > .value {
      padding: .3em;
      padding-left: 0;
      min-height: 1.2em;
      line-height: 1.2em;
    }

    label.focused {
      font-size: .8em;
      transform: translate3d(0,-1.2em,0)
    }
  }

  &.filter, &.standard {
    margin-top: 1.1em;
    border-bottom: 1px solid $otcLightGray;
    > input {
      min-height: 1.4em;
      line-height: 1.4em;
      padding: .3em;
      padding-left: 0;

      @include placeholder {
        color: $otcGray !important;
        line-height: 1.4em;
      }
    }
    label {
      color: $otcGray;
    }
    .value {
      &:empty { display: none; }
    }
    .value, label {
      min-height: 1.4em;
      line-height: 1.4em;
      padding: .3em;
      padding-left: 0;
    }

    &.focused {
      border-color: $otcGreen;
    }
    > svg {
      color: $otcGray;
    }
  }


  &.filter {
    @include md-lg {
      border: none;
      font-weight: bold;
      .value, label {
        text-align: right;
      }
      svg {
        position: relative;
        bottom: 1px;
      }
    }
    @include sm {
      &:only-child {
        margin-top: 0.1em !important;
      }
    }
  }


  &.outline {
    border: 1px solid $otcGreen;
    padding: .25em .34em;
    height: 2.15rem;
    > input {
      font-weight: bold;
      line-height: 1.5em;
      padding-left: .1em;
      color: $otcDarkGray;

      @include placeholder {
        color: $otcGreen !important;
        line-height: 1.5em;
      }
    }
    > svg {
      color: $otcGreen;
      font-size: 1.2rem
    }
  }

  &.light {
    > .value, > label {
      min-height: 1.7em;
      line-height: 1.7em;
    }
  }

  .errorMessage {
    color: $otcErrorColor;
    font-size: .8em;
    position: absolute;
    left: 0;
    top: 2.8em;
    animation: fadein 0.5s;

    @keyframes fadein {
      from { top: 2.2em; opacity: 0; }
      to   { top: 2.8em; opacity: 1; }
    }
  }
}
