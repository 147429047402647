@import '../../global.scss';

.CompanyQuoteQuote {
  @include lg {
    margin-top: -1.5em;
  }
}

.tradeDataTable {
  margin-bottom: 3em;
}

.shortSalesTable {
  margin-bottom: 1em;
}

.pqeTable {
  margin-bottom: 3em;
}

.footerNote {
  font-size: 0.7em;
  font-weight: $fontWeightLight;
  color: $otcGray;
  margin-top: 1.5em;
  display: block;
}
