@import '../../global.scss';

.component {
  p {
    margin: 0;
  }
}

.table {
  position: relative;
  margin-bottom: 3em;
}

.details {
  border-top: 3px solid $otcGreen;
  padding-top: .8em;
  margin-bottom: 2em;
}

.tabs {
  position: relative;
  margin-bottom: 3em;
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

// details

.companyInfo {
}

.separator {
  height: 1px;
  background-color: $otcLightGray;
  margin: .8em 0;
}

.logo {
  margin: .7em 0;
  width: 150px;
  height: 50px;
  object-fit: contain;
}

.companyName {
  font-size: 1.2em;
  font-weight: bold;
}

.companyTypes {

}

.description {
  font-family: $otcFontSecondary;
  color: $otcDarkGray;
  font-weight: lighter;
  border-top: 1px solid $otcGreen;
  padding-top: 1.5em;
  padding-bottom: 2.5em;
}

.assocCompanies {
  margin-bottom: 3em;
}
