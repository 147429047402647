@import '../../global.scss';

.pageTitle {
  @include h1;

  margin-top: 0;
  max-width: 85vw;
  line-height: 1em;
  font-weight: bold;
  @include md-lg {
    min-width: 25rem;
  }
  @include sm {
    min-width: 60vw;
  }
}

.color-green { color: $otcGreen; }
.color-orange { color: $otcOrange; }
.color-pink { color: $otcPink; }
