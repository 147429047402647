@import '../../global.scss';

.CompanyQuoteOverview {
  &.green {
    a {
      &:hover {
        color: $otcGreen;
      }
    }
  }

  &.pink {
    a {
      &:hover {
        color: $otcPink;
      }
    }
  }

  &.orange {
    a {
      &:hover {
        color: $otcOrange;
      }
    }
  }

  &.gray {
    a {
      &:hover {
        color: $otcGray;
      }
    }
  }

  @include lg {
    margin-top: -1.5em;
  }
  
  .tradeDataTable {
    abbr {
      font-size: 0.7em;
      font-weight: $fontWeightLight;
      color: $otcGray;
      margin-top: 1.5em;
      display: block;
    }

    margin-bottom: 3em;
  }

  .newsSection, .financialsSection {
    margin-bottom: 3.5em;
  }

  .companyDescriptionContainer {
    align-items: stretch;
  }

  .companyDescription {
    margin-bottom: 3.5em;
    border-bottom: 1px solid $otcLightGray;

    .companyLogo {
      padding: 1em;
      border-right: 1px solid $otcLightGray;
      margin-right: 1.6em;
      display: inline-flex;
      align-items: center;

      img {
        width: 10em;
      }

      @include sm {
        display: none;
      }
    }

    .companyDescriptionText {
      padding: 1.6em 0;
      font-family: $otcFontSecondary;
      font-size: 0.9em;
      color: $otcDarkGray;
      font-weight: $fontWeightLight;
    }
  }

  .dataContainer {
    margin-bottom: 3em;
  }

  .boldBorder {
    border-width: 2px !important;
  }
}
