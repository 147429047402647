@import '../../global.scss';

.table {
  position: relative;
  margin-bottom: 3em;
}

.filterSelect {
  border-top: 3px solid $otcGreen;
  margin-bottom: 3em;
}

.tabs {
  position: relative;
  margin-bottom: 3em;
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

.sideColumn {
  @include sideColumn;
}

/*
.map {
  margin-bottom: 3em;
}

.mapLoader {
  height: 100%;
}

.mapContainer {
  position: relative;
  height: 400px;
}

.mapInner {
  height: 100%;
}
*/

.outlineHeaderSecondary {
  text-transform: none;
  font-weight: normal;
  @include sm { display: none; }
}

.sponsorTitleWrapper {
  align-items: stretch;
  margin: 1em 0;

  .text {
    @include sm {
      margin-bottom: 10px;
    }
  }

  .buttonContainer {
    // height: 100%;
    text-decoration: none;

    @include sm {
      height: 50px;
    }
  }

  .applyButton {
    position: relative;
    display: block;
    text-transform: unset;
    width: 100%;
  }
}