.container {
  height: 100vh;
  padding-top: 75px;
  background-color: #fff;
  text-align: center;
}

.logo {
  display: block;
  width: 150px;
  margin: 0 auto 20px;
}

.footer {
  display: flex;
  text-align: center;
  width: 350px;
  margin: 50px auto;
  justify-content: space-around;
}