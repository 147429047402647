@import '../../global.scss';

.Subscribe {
  position: relative;
  margin-top: 3em;
  border: 8px solid $otcLightGray;
  padding: 0.7em;

  @include md {
    margin: 0;
  }
}

.emailInputContainer {
  position: relative;
}

.emailInput {
  margin: 1em 0 .6em 0 !important;
  border-color: $otcGreen !important;
  input {
    padding-right: 5em !important;
  }
}

.signupButton {
  position: absolute;
  right: .2em;
  bottom: .25em;
  outline: none;
  border: none;
  background: transparent;
  color: $otcGreen;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 700;
  padding: .0;
  font-size: 1.05em;
  cursor: pointer;
}

.title {
  font-size: 1.125rem;
  margin-bottom: .34rem;
  font-weight: bold;
}

.description {
  font-size: 0.9em;
  font-weight: lighter;
  @include sm {
    display: none;
  }
}

.success {
  margin-top: 1.3rem;
  margin-bottom: .75rem;
  font-weight: bold;
  color: $otcGreen;
  font-size: 1.1rem;
}

.error {
  margin-top: .4rem;
  font-size: .9rem;
  color: $otcErrorColor;
  font-size: 1em;
}
