@import '../../global.scss';

.component {
  border-top: 3px solid $otcGreen;
  padding-top: 2em;
  @include sideColumn;
}

.sticky {
  position: fixed;
}

.title {
  color: black;
  font-weight: bold;
  margin-bottom: .5em;
}

.link {
  display: flex;
  color: $otcDarkGray;
  cursor: pointer;
  margin-bottom: .3em;
  align-items: center;
  text-decoration: none;
  font-weight: $fontWeightBold;

  &:last-child {
    margin-bottom: 0;
  }
}

.linkIcon {
  margin-left: auto;
  color: $otcGreen;
}
