@import "../../../global.scss";

.button {
  padding-right: 1em;
  display: inline-block;
  color: $otcDarkGray;

  svg {
    padding-right: 0.5em;
    color: $otcGray;
  }
}
