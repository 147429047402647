@import '../../global.scss';

.component {
  position: relative;
  height: 1.8rem;
  width: 100%;
  input {
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    height: 100%;
    padding-bottom: .2rem;
    font-family: inherit;

    @include placeholder {
      color: #aaa !important;
    }
  }

  svg {
    color: $otcGreen;
    position: absolute;
    right: 0;
    bottom: .5em;
  }

  .button {
    cursor: pointer;
  }
}

