@import '../../global.scss';

$selectedBorderWidth: 4px;

.component {
  height: 3.4em;
  padding: 0;
  margin: 0;
  font-size: 0.9em;

  &.relative {
    position: relative;
    top: 0;

    .tableItem {
      margin: 0 1em;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @include lg {
    position: absolute;
    top: 5px;
    right: 0;
  }

  @include sm-md {
    width: 100%;

    &.green, &.default {
      border-bottom: 1px solid $otcGreen;
    }

    &.orange {
      border-bottom: 1px solid $otcOrange;
    }

    &.pink {
      border-bottom: 1px solid $otcPink;
    }

    &.gray {
      border-bottom: 1px solid $otcGray;
    }

    &.black {
      border-bottom: 1px solid #000;
    }
  }
}

.tableItem {
  margin: 0 0.5em;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  line-height: 3.4em;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: -1px;
  }
}

.selected {
  font-weight: bold;
  border-bottom: $selectedBorderWidth solid $otcGreen;

  &.green, &.default {
    border-bottom: $selectedBorderWidth solid $otcGreen;
  }

  &.orange {
    border-bottom: $selectedBorderWidth solid $otcOrange;
  }

  &.pink {
    border-bottom: $selectedBorderWidth solid $otcPink;
  }

  &.gray {
    border-bottom: $selectedBorderWidth solid $otcGray;
  }

  &.black {
    border-bottom: $selectedBorderWidth solid #000;
  }
}

.download {
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  border-left: 1px solid #e2e2e2;
  margin-left: 15px;
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}