@import '../../global.scss';

.details {
  border-top: 3px solid $otcGreen;
  padding-top: .8em;
  margin-bottom: 3em;
  @include sm-md {
    margin-bottom: .9em;
  }
}

.separator {
  height: 1px;
  background-color: $otcLightGray;
  margin: .7em 0;
}

.companyName {
  font-size: 1.2em;
  font-weight: bold;
}

.description {
  border-top: 1px solid $otcGreen;
  padding-top: 1em;
  padding-bottom: 3em;
  font-family: $otcFontSecondary;
  color: $otcDarkGray;
  font-weight: lighter;
  @include sm-md {
    border-top: 1px solid $otcLightGrey;
    padding-bottom: 0;
  }
}

.prohibited {
  border-top: 1px solid $otcLightGray;
  margin-top: .6em;
  padding-top: .6em;
  font-weight: bold;
  color: $otcGray;
  svg {
    font-size: 1.1em;
    margin-right: .1em;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}

.tableHeading {
  @include sm-md {
    margin-top: 3em !important;
  }
}
