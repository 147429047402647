@import '../../../global.scss';

.item {
  width: 100%;
  font-size: 0.9em;

  .field {
    padding-bottom: .5em;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
