@import '../../global.scss';

.video {
  cursor: pointer;

  .content {
    position: relative;
    background: $otcLightGray;
    padding-top: 25%;
    padding-bottom: 25%;
    height: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin-bottom: .6rem;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 100%;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .title {
    font-weight: $fontWeightBold;
  }
}
