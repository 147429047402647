@import '../../global.scss';

.component {
  p {
    margin: 0;
  }
}

.desc {
  border-top: 3px solid $otcGreen;
}

.table {
  position: relative;
  margin-bottom: 3em;
  @include sm-md {
    border-top: none;
  }
}

.sideColumn {
  @include sideColumn;
}