@import '../../global.scss';

.more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  border-top: 1px solid $otcLightGray;
  border-bottom: 1px solid $otcLightGray;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: $fontWeightBold;
  cursor: pointer;
  width: 100%;
  height: 2.15rem;
  color: $otcDarkGray;
  font-size: 0.86rem;
  line-height: 1em;
  transition: color 150ms;

  .iconRight, .iconDown {
    font-weight: $fontWeightLight;
    font-size: 0.8em;
    margin-left: 1em;
  }
  .iconDown {
    position: relative;
    bottom: .1em;
  }

  &:hover {
    color: $otcDarkGray;
  }

  &.disabled {
    display: none;
  }
}
