@import '../../global.scss';

.row {
  border-bottom: 1px solid $otcLightGray;

  &.green {
    color: $otcGreen;
  }

  &.pink {
    color: $otcPink;
  }

  &.orange {
    color: $otcOrange;
  }

  &.gray {
    color: $otcGray;
  }
}

.value {
  text-align: right;
}

.bold {
  font-weight: $fontWeightBold;
}