@import '../../global.scss';

.wrapper {
  position: relative;
  width: 100%;
  left:0;
  bottom: 0;
  margin-top: 4em;
}

.preloginWrapper {
  margin: 0;
}

.footer {
  background-color: $otcBlack;
  color: white;
}

.container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 1em;
}

.stockQuoteWidget {
  align-self: stretch;
  border-bottom: 1px solid #fff;
}

.copyright {
  @include caption;
  color: white;
  line-height: 1.8em;
  margin: 2.5em auto;
  text-align: center;

  p {
    display: inline;
    padding-right: .5em;
    font-weight: lighter;
  }

  a {
    color: white;
    text-decoration: none;
    padding: 0 .5em;
    border-left: solid 1px #ffffff;
    font-weight: bold;

    &:first-child {
      border-left: none;
    }

    &.active {
      color: $otcGreen;
    }
  }
}
