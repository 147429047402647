@import '../../global.scss';

.select {
  cursor: pointer;
}

.dropdown {
  background: $otcWhite;
  font-size: 0.9rem;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0.2rem;
  z-index: 100;
  opacity: 1;
  overflow: hidden;
  overflow-y: auto;
  height: 0;
  max-height: 25em;
  box-shadow: none;
  border: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  min-width: 18rem;
  text-align: left;

  .container {
    padding: 0;
    margin: 0;
  }

  .menuItem {
    padding: 0.5rem 1rem;
    margin: 0;
    border-top: 1px solid $otcLightGray;
    cursor: pointer;
    min-height: 2.2rem;

    &.clear {
      color: $otcGray;
      font-style: italic;
    }

    &.submenu {
      display: none;
    }

    &.submenu.submenuOpen {
      display: flex;
      padding-left: 2rem;
    }

    &.active {
      background: #f5f5f5;
    }

    &.selected {
      background: $otcGreen;
      color: $otcWhite;

      .checkboxUnchecked {
        color: $otcWhite;
      }

      .checkboxChecked {
        color: $otcWhite;
      }
    }
  }
}

.dropdown.open {
  border: 1px solid $otcLightGray;
  opacity: 1;
  height: auto;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
}

.checkboxUnchecked {
  margin-right: 0.5rem;
  color: $otcGreen;
}

.checkboxChecked {
  margin-right: 0.5rem;
  color: $otcGreen;
}

.caret {
  color: $otcGray;
  padding-left: .8em;
  margin-right: .04em;
  display: flex;
  align-items: center;
}

.centerCaret {
  color: $otcDarkGray;
  padding-left: .8em;
  font-weight: lighter;

  svg {
    padding-bottom: 0.4em;
  }
}

.centerAligned {
  text-align: center;
}

.parentIconClosed, .parentIconOpen {
  font-size: 0.5em;
  padding: 0.2rem 0;
  color: $otcGray;
}

.parentItem {
  width: 100%;
}

.hiddenInput {
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  flex: none !important;
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
}
