@import '../../global.scss';

.component {
  p {
    margin: 0;
  }
}

.table {
  position: relative;
  margin-bottom: 3em;
}

.headingCount {
  font-size: 1em;
  font-weight: bold;
  @include sm { display: none; }
}

.otherDirectories {
  margin-bottom: 3em;
}


.sideColumn {
  @include sideColumn;
}