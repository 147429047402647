@import '../../global.scss';

.container {
  .security {
    &:first-child {
      margin-top: 0;
    }

    display: flex;
    justify-content: space-between;
    margin: 2.25em 0 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $otcLightGray;
    text-align: right;
    cursor: pointer;
    text-decoration: none;

    .title {
      line-height: 1em;
    }
  }
}