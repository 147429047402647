@import '../../global.scss';

.CompanyQuoteFinancials {
  abbr {
    color: $otcGray;
    font-size: 0.8em;
    padding: 1em 0;
    display: block;

    img {
      height: 4em;
      margin-right: 1em;
    }

    p {
      margin-bottom: 10px;
    }
    
    p:last-child {
      margin-bottom: 0px;
    }
  }
}

.tabDivider {
  border-top: 2px solid $otcLightGray;
}

.tab {
  font-size: 0.8em;
  border-bottom: 1px solid $otcLightGray;
  font-weight: $fontWeightBold;
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
  cursor: pointer;
  margin-top: 0;
  padding-top: 1em;

  &.active {
    border-bottom: 1px solid $otcGreen;

    &.green, &.default {
      border-bottom: 1px solid $otcGreen;
    }

    &.orange {
      border-bottom: 1px solid $otcOrange;
    }

    &.pink {
      border-bottom: 1px solid $otcPink;
    }

    &.gray {
      border-bottom: 1px solid $otcGray;
    }

    &.black {
      border-bottom: 1px solid #000;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.mobileTab {
  font-size: 0.8em;
  font-weight: $fontWeightBold;
  padding-top: 0.7em;
}

.mobileFilter {
  padding-bottom: 0.5em;
}

.row:hover {
  background-color: #F1F1F1;
}
.row {
  border-bottom: 1px solid $otcLightGray;
  padding-top: 0.15em;
  padding-bottom: 0.1em;
  min-height: 25px;
  color: #000000;
  font-size: 0.9em;

  .value {
    text-align: right;
    word-break: break-word;
    padding-left: 1em;

    @include sm {
      display: none;

      &.selected {
        display: inline;
      }
    }

    @include md {
      display: none;

      &.selected {
        display: inline;
      }
    }
  }

  &.hideOnMobile {
    @include sm {
      display: none;
    }
  }

  &.hideOnTablet {
    @include md {
      display: none;
    }
  }

  &.bold {
    font-weight: $fontWeightBold;
  }

  &.section {
    font-weight: $fontWeightBold;
    font-size: 1.0em;
    text-transform: uppercase;
  }

  &.totals {
    font-weight: $fontWeightBold;
    font-size: 14px;
  }

  &.gray {
    color: $otcGray;
    pointer-events: none;
  }

  &.topBorder {
    border-top: 1px solid $otcLightGray;
  }

  &.bottomSpacing {
    margin-bottom: 25px;
  }

  &.separator {
    background-color: #E6E5E5;
    font-size: 14px;
    font-weight: bold;
    border-top: 0.1em  solid $otcGray;
    border-bottom: 0.1em  solid $otcGray;
  }

  &.firstSubSeparator {
    background-color: #F0F0F0;
    font-weight: 600;
    border-bottom: 0.1em  solid $otcGray;
  }

  &.subSeparator {
    background-color: #F0F0F0;
    font-weight: 600;
    border-top: 0.1em  solid $otcGray;
    border-bottom: 0.1em  solid $otcGray;
  }

  &.child {
    padding-top: 0.5em;
    padding-bottom: 0;
    color: $otcGray;
  }

  &.hideUnderline {
    border-bottom: none;
  }
}

.mobileTimeSelect {
  text-align: center;
  font-weight: $fontWeightBold;
  font-size: 0.85em;
  text-transform: uppercase;
  color: $otcLightGray;
  padding: 0.61em 1em 0.5em 1em;
  margin-bottom: -2px;
  cursor: pointer;

  &.selected {
    color: $otcDarkGray;
    border-bottom: 2px solid $otcGreen;
  }
}

.footerNote {
  margin-bottom: 1.5em;
}

.itemTitle {
  min-width: 40%;
  padding-left: 20px;
  &.separator {
    padding-left: 5px;
  }

  &.totals {
    padding-left: 5px;
  }

  &.subRow {
    padding-left: 45px;
  }
}

.itemTitleContent {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
