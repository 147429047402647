@import "../../global.scss";

.MarketActivityOverview {
  border-top: $dividerMedium;
  margin-bottom: 1em;

  .select {
    font-weight: bold;
    margin-bottom: 1em;
  }

  .marketInfo {
    width: 100%;
    margin-top: 0em;
    margin-bottom: 0.5em;

    td {
      padding-top: 1em;
    }

    .marketItem {
      margin-top: 1em;
      > div {
        font-size: 0.7em;
        font-weight: $fontWeightLight;
        color: $otcGray;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }

    p {
      font-weight: bold;
      border-bottom: 1px solid $otcLightGray;
      margin: 0;
    }
  }

  abbr {
    font-size: 0.7em;
    font-weight: $fontWeightLight;
    color: $otcGray;
  }
}
