@import '../../global.scss';

.MACorpActionsPage {
  p {
    margin: 0;
  }
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

.filter {
  border-top: $dividerMedium;
  margin-bottom: 2em;
}
.filterLeft {
  margin-right: 1em;
}
.filterRight {
  margin-left: 1em;
}

@include md {
  .footerNote {
    margin-bottom: 4em;
  }
}

.sideColumn {
  @include sideColumn;
}