@import '../../global.scss';

@include lg {
  .main {
    flex-direction: column;
    flex: 1 1 100%;
  }
  .side {
    flex-direction: column;
    flex: 0 0 $sidebarWidth;
    max-width: $sidebarWidth;
    &:first-child { margin-right: $sidebarMargin; }
    &:last-child { margin-left: $sidebarMargin; }
  }
}
