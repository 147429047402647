@import '../../global.scss';

.CompanyQuoteProfile {
  .companyInfo {
    font-size: 0.9em;

    a {
      text-decoration: none !important;
      display: block;
    }

    .companyLogo {
      img {
        max-width: 12em;
        margin: .5em 0 .8em 0;
      }
    }

    .companyName {
      margin: 0;
    }

    .socialButtons {
      a {
        color: $otcWhite;
        display: inline-block;
      }

      .socialButton {
        cursor: pointer;
        margin-top: 0.5rem;
        border-radius: 100%;
        background-color: $otcGreen;
        color: $otcWhite;
        font-size: 1.2rem;
        width: 2.2rem;
        height: 2.2rem;
        line-height: 2.2rem;
        margin-left: .7rem;
        text-align: center;

        &.green {
          background-color: $otcGreen;
        }

        &.orange {
          background-color: $otcOrange;
        }

        &.pink {
          background-color: $otcPink;
        }

        &.gray {
          background-color: $otcGray;
        }

        &.black {
          background-color: #000;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .description {
    padding-left: 3em;

    @include sm {
      padding: 0;
    }
  }

  .descriptionText {
    padding: 1em 0;
  }

  .execAddress {
    padding-top: 0.5em;
    margin-top: 0.5em;
    border-top: 1px solid $otcLightGray;
  }

  .serviceProviders {
    margin-left: -25px;
    width: calc(100% + (25px * 2));
    margin-bottom: -1em;
    .block {
      margin: 1em 0 2em 0;
      padding: 0 25px;

      .address {
        white-space: pre-line;
      }

      .title {
        font-size: 1em;
        font-weight: $fontWeightBold;
        border-bottom: 1px solid $otcLightGray;
        margin-bottom: 1em;
        padding-bottom: 0.5em;
      }
    }

    a {
      text-decoration: none;
    }

    .sponsorName{
      margin-right: .6em;
      padding-right: .6em;
    }

    .sponsor {
      font-size: .88em;
      font-weight: normal;
      color: $otcDarkGray;
      display: inline-block;

      img {
        width: 1em;
        padding-right: 0.34em;
        padding-bottom: .14em;
        vertical-align: middle;
      }
    }

    .prohibited {
      font-size: .88em;
      font-weight: normal;
      margin-left: .6em;
      padding: .1em .6em;
      border: 1px solid #FF0000;
      border-radius: 6px;
      color: #FF0000;
      font-weight: bold;
      text-decoration: none;
      svg {
        font-size: .95em;
        margin-bottom: .2em;
      }
    }
  }
}

.viewCta {
  text-decoration: none;
}

.view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  border-top: 1px solid $otcLightGray;
  border-bottom: 1px solid $otcLightGray;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: $fontWeightBold;
  cursor: pointer;
  width: 100%;
  height: 2.15rem;
  color: $otcDarkGray;
  font-size: 0.86rem;
  line-height: 1em;

  svg {
    margin-left: 5px;
  }
}

.footerNote {
  font-size: 0.7em;
  font-weight: $fontWeightLight;
  color: $otcGray;
  margin-top: 1.5em;
  display: block;
}

.subHeader {
    @include caption;
    position: relative;
    line-height: 3.2em;
    text-align: right;
    width: 100%;
    font-weight: $fontWeightSemiBold;
    vertical-align: middle;
}