@import '../../global.scss';

.component {
  position: relative;
  color: $otcWhite;
  width: 100%;
  padding: 35px 1em;
  box-sizing: border-box;
  background-color: $otcGreen;
  font-size: 18px;

  &.reminder,
  &.note {
    background-color: $otcGreen;
  }

  &.important {
    background-color: $otcWarningColor
  }
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 1em;
  box-sizing: border-box;
}

.pagination {
  display: block;
  max-width: 1280px;
  margin: 15px auto 0;
  padding: 0 1em;
  box-sizing: border-box;
  font-weight: $fontWeightLight;

  span {
    cursor: pointer;

    &:first-child {
      margin-right: 15px; 
    }
  }
}

.icon {
  margin-right: 10px;
}

.link {
  color: $otcWhite;

  &:hover {
    color: $otcWhite;
  }
}

.cta {
  display: flex;
  align-items: center;
  height: 25px;
  cursor: pointer;
  border: $otcWhite 2px solid;
  padding: 0 15px;
  font-size: 14px;
}