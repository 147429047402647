@import '../../global.scss';

label {
  display: inline-block !important;
  img {
    vertical-align: middle;
    position: relative;
    width: 10px;
    left: .1em;
    bottom: 5px;
  }
}
