@import '../../global.scss';

.outline {
  padding: 0.5em 0;
  margin: 0.5em 0;

  &.heading {
    border-top: $dividerThick;
    border-bottom: 1px solid $otcGreen;
  }

  &.section1 {
    border-top: 1px solid $otcLightGray;
    border-bottom: 1px solid $otcGreen;
  }

  &.section2 {
    border-top: 1px solid $otcLightGray;
    border-bottom: 1px solid $otcLightGray;
  }

  &.section3 {
    border-top: 1px solid $otcGreen;
    border-bottom: 1px solid $otcLightGray;
  }

  &.invisible {
    border-top: none;
    border-bottom: none;
  }

  &.largeSpacing {
    padding: 0.93em 0 !important;
    margin: 0.93em 0 !important;

    @include md {
      padding: 0 0 !important;
      margin: 0 0 !important;
    }
  }

  &.largerSpacing {
    padding: 1.5em 0 !important;
    margin: 1.5em 0 !important;
  }

  &.noSpacing {
    padding: 0 0 !important;
    margin: 0 0 !important;
  }

  &.noMargin {
    margin: 0 !important;
  }

  &.marginTop {
    margin-bottom: 0 !important;
  }

  &.marginBottom {
    margin-top: 0 !important;
  }

  &.accordion {
    justify-content: space-between;
    align-items: center;
  }

  &.heading {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    &:empty {
      padding: 0;
    }

    .text {
      @include h3;
      font-weight: $fontWeightBold;
      line-height: 1.2em;
      padding: .6em 0;
      width: 100%;
      text-align: left;
    }
  }

  &.heading2 {
    font-weight: $fontWeightBold;
    line-height: 1em;
    padding: 0;
    margin: 0;
    border-top: 1px solid $otcGreen;
    border-bottom: 1px solid $otcGreen;
    font-size: 1em;
    text-transform: uppercase;
    padding: 0.8em 0;
    margin: 1em 0;
  }

  &.heading3 {
    font-weight: $fontWeightBold;
    line-height: 1em;
    padding: 0;
    border-bottom: 1px solid $otcGreen;
    font-size: 1.1em;
    padding: 0.4em 0;
    margin: 0.5em 0;
  }

  &.heading4 {
    display: flex;
    flex-wrap: nowrap;
    font-weight: $fontWeightBold;
    font-size: 1.13em;
    padding: 0;
    margin: 0;
    border: none;
  }

  &.line {
    border-bottom: none;
  }

  &.underline {
    border-top: none;
  }

  &.green, &.default {
    &.heading, &.heading2 {
      border-top-color: $otcGreen;
      border-bottom-color: $otcGreen;
    }

    &.section1, &.heading3 {
      border-bottom-color: $otcGreen;
    }

    &.section3 {
      border-top-color: $otcGreen;
    }
  }

  &.pink {
    &.heading, &.heading2 {
      border-top-color: $otcPink;
      border-bottom-color: $otcPink;
    }

    &.section1, &.heading3 {
      border-bottom-color: $otcPink;
    }

    &.section3 {
      border-top-color: $otcPink;
    }
  }

  &.orange {
    &.heading, &.heading2 {
      border-top-color: $otcOrange;
      border-bottom-color: $otcOrange;
    }

    &.section1, &.heading3 {
      border-bottom-color: $otcOrange;
    }

    &.section3 {
      border-top-color: $otcOrange;
    }
  }

  &.gray {
    &.heading, &.heading2 {
      border-top-color: $otcGray;
      border-bottom-color: $otcGray;
    }

    &.section1, &.heading3 {
      border-bottom-color: $otcGray;
    }

    &.section3 {
      border-top-color: $otcGray;
    }
  }

  &.black {
    &.heading, &.heading2 {
      border-top-color: #000;
      border-bottom-color: #000;
    }

    &.section1, &.heading3 {
      border-bottom-color: #000;
    }

    &.section3 {
      border-top-color: #000;
    }
  }
}

.rightContent {
  margin-left: 2em;
  display: flex;
  align-items: center;
  font-weight: normal;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.download {
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-left: 1px solid #e2e2e2;
  margin-left: 15px;
  height: 100%;
  cursor: pointer;
}

.buttons {
  vertical-align: top;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 1em;

  .button {
    border-left: 1px solid $otcLightGray;
    color: $otcGray;
    font-size: 1.2em;
    margin: 0;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 .7em;
  }

  .hideOnMobile {
    @include sm {
      display: none;
    }
  }

  .hideOnTablet {
    @include md {
      display: none;
    }
  }
}

.toggleButton {
  font-size: .75em;

  &:hover {
    color: inherit;
  }

  transition: transform .25s ease-in-out;

  &.open {
    transform: rotate(180deg);
  }
}