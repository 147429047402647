@import "../../global.scss";

.list {
  position: relative;

  .chevron {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2em 1em;
    font-size: 0.5em;
    font-weight: lighter;
    cursor: pointer;
  }

  .item {
    padding: 0.4em 0;

    &.borders {
      border-bottom: 1px solid $otcLightGray;
    }

    .title {
      font-size: 1.1em;
      font-weight: $fontWeightBold;
      margin-top: 0.5em;
    }
  }

  .container {
    display: none;

    &.display {
      display: block;
    }
  }
}
