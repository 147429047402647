.accordion {
  .accordionItem {
    .title {
      background-color: transparent;
      cursor: pointer;
      width: 100%;
      border: none;
      outline: none;
      transition: 0.4s;
      padding: 0;
    }

    .panel {
      transition: max-height 0.25s ease-out;
      overflow: hidden;
    }

    &.active {
      .panel {
        transition: max-height 0.25s ease-in;
      }
    }
  }
}
