@import '../../global.scss';

.tab {
  margin-right: 1em;
  position: relative;
  cursor: pointer;
  text-align: right;

  &.active {
    border-bottom: 1px solid $otcGreen;
  }

  &:last-child {
    margin-right: 0;
  }

  @include sm {
    text-align: left;
    margin: 0;
    width: 100%;
  }
}

.info {
  border-top: $dividerMedium;
  padding: 1em 0;
  p {
    margin: 0;
    font-family: $otcFontSecondary;
  }
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

.table {
  position: relative;
  margin-bottom: 3em;
}

.sideColumn {
  @include sideColumn;
}