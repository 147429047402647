@import "../../../global.scss";

.item {
  .content {
    font-family: $otcFontSecondary;
    color: $otcDarkGray;
    font-weight: lighter;
  }

  .social {
    color: $otcLightGray;
    font-size: 1.2em;
    letter-spacing: 1em;
    margin-top: 0.5em;

    a {
      margin-right: 0.5em;
      color: $otcLightGray;
    }
  }
}
