@import '../../global.scss';

.link {
  display: inline-block;
  vertical-align: top;
  color: $otcGray;
  font-size: .88em;
  text-decoration: none;
  text-transform: uppercase;
  height: 1.8rem;
  cursor: pointer;

  svg {
    margin-right: 0.35em;
    position: relative;
    bottom: .13em;
  }
}
