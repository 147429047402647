@import '../../global.scss';

.breadcrumbs {
  font-size: .75em;
  color: $otcGray;
  padding: 0;
  margin-top: .5em;
  margin-bottom: 1.4em;
  list-style: none;

  li {
    font-weight: 300;
    display: inline-block;
    padding-right: .3em;
    &::after {
      content: "/";
      padding-left: .3em;
    }
  }

  & > li:last-child::after {
    display: none;
  }

  & > li:first-child {
    display: none;
  }

  li a {
    cursor: pointer;
    color: $otcGray;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
