@import '../../global.scss';

.container {
  position: relative;
}

.static {
  opacity: .5;
}

.modal {
  position: absolute;
  background: $otcWhite;
  border: 1px solid $otcLightGrey;
  padding: 35px 70px;
  width: 500px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: 1;
  margin-top: 20px;
}

.map {
  padding: 60px;
}

.header {
  display: flex;

  .date {
    margin-left: auto;
  }
}

.headerDate {
  font-size: 14px;
  font-weight: lighter;
  font-style: italic;
}

.dateContainer {
  display: block;
  width: 270px;
  margin-left: auto;
  margin-bottom: 30px;
}

.staticRow {
  font-weight: 700;

  .staticBlank {
    display: block;
    background-color: $otcGrey;
    width: 100px;
    height: 20px;
  }
}

.staticBlock {
  display: block;
  width: 100px;
  height: 20px;
  background-color: $otcLightGrey;
}