@import '../../global.scss';

.component {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  margin-top: .1em;
  font-size: 18em;
  font-weight: bold;
  line-height: 1em !important;
  span {
    line-height: 1em !important;
  }
  span:nth-child(1) { color: $otcGreen; }
  span:nth-child(2) { color: $otcOrange; }
  span:nth-child(3) { color: $otcPink; }

  @include md {
    font-size: 15em;
  }
  @include sm {
    font-size: 9em;
  }
}
.heading {
  text-align: center;
  font-size: 1.75em;
  line-height: 1.8em;
  font-weight: bold;
}
.subheading {
  text-align: center;
  font-size: 1em;
  font-weight: 100;
}
