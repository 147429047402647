@import '../../global.scss';

.container {
  h4 {
    margin: 0;
    text-align: center;
    line-height: 20px;
  }

  .spacer {
    border-top: 1px solid $otcLightGrey;
    margin: 25px 0 20px;
  }

  svg {
    font-size: 1.25em;
    margin: 7px 5px 7px 0;
    vertical-align: middle;
  }
}

.phone {
  color: $otcGreen;
}

.link {
  a {
    color: $otcGreen;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
