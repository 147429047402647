@import '../../global.scss';

@mixin pageWidth {
  max-width: 1440px;
  padding: 0 50px;
  margin: 0 auto;
}

@mixin columnGap {
  grid-column-gap: 3em;
}

.container {
  background-color: $otcBlack;
  color: $otcWhite;
  box-sizing: border-box;

  h1, h2, h3, h4, h5, p {
    margin: 0;
  }
}

.section {
  box-sizing: border-box;
}

.hero {
  @include lg {
    @include pageWidth;
    height: 627px;
  }

  @include md {
    @include pageWidth;
    padding: 50px;
    height: 500px;
  }

  box-sizing: border-box;
  padding: 25px;
  height: 320px;
  display: block;
  color: #000;

  h1 {
    @include lg {
      font-size: 68px;
      line-height: 95px;
      padding-top: 25px;
      max-width: 500px;
    }

    @include md {
      font-size: 50px;
      font-weight: 900;
      max-width: 500px;
      padding-top: 25px;
    }
    
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 110px;
  }

  .textCta {
    @include lg {
      display: inline-block;
      margin-left: 35px;
      font-size: 18px;
    }

    @include md {
      margin: 20px 0 0;
      font-size: 18px;
    }

    display: block;
    margin: 10px 0 0;
    font-size: 12px;
    color: $otcBlack;
    font-weight: $fontWeightBold;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.ctaButton {
  @include lg {
    height: 60px !important;
    width: 315px;
    font-size: 18px !important;
  }

  @include md {
    height: 60px !important;
    width: 315px;
    font-size: 18px !important;
  }
}

.hero2 {
  h1 {
    @include lg {
      font-size: 68px;
      font-weight: 900;
      max-width: 500px;
      padding-top: 120px;
    }

    @include md {
      font-size: 50px;
      font-weight: 900;
      max-width: 500px;
      padding-top: 25px;
    }
    
    padding-top: 25px;
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 110px;
  }
}

.section2 {
  @include lg {
    @include pageWidth;
    padding-top: 105px;
    padding-bottom: 105px;
  }

  @include md {
    padding: 45px 50px;
  }
  padding: 35px 25px;
  box-sizing: border-box;
}

h3.desc {
  @include lg {
    font-size: 28px;
    letter-spacing: .3px;
  }

  @include md {
    font-size: 28px;
    letter-spacing: .3px;
  }

  font-size: 18px;
}

.leftCard {
  @include lg {
    @include pageWidth;
    display: grid;
    grid-template-columns: 2fr 1fr;
    @include columnGap;
    box-sizing: border-box;
  }
}

.rightCard {
  @include lg {
    @include pageWidth;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @include columnGap;
    margin-top: 80px;
    box-sizing: border-box;
  }

  margin-top: 50px;
}

.card {
  @include lg {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 200px;
    padding-left: 50px;
  }

  @include md {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 100px;
    padding-left: 50px;
  }

  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 50px;
  padding-left: 15px;
  background-color: $otcWhite;
  box-sizing: border-box;
  color: #000;
  box-sizing: border-box;

  h3 {
    @include lg {
      font-size: 36px;
    }

    @include md {
     font-size: 36px;
    }

    font-size: 24px;
  }

  p {
    @include lg {
      font-size: 24px;
    }

    @include md {
      font-size: 24px;
    }

    font-size: 18px;
  }
}

.sectionRiskScore {
  @include lg {
    @include pageWidth;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @include columnGap;
    margin-top: 100px;
    margin-bottom: 150px;
  }

  margin-bottom: 100px;
  box-sizing: border-box;
  
  .title {
    @include lg {
      font-size: 50px;
    }

    @include md {
      font-size: 50px;
    }

    font-size: 28px;
  }

  .desc {
    @include lg {
      font-size: 24px;
      letter-spacing: 0;
      padding: 75px 0 0 50px;
    }

    @include md {
      font-size: 24px;
      letter-spacing: 0;
      padding: 75px 0 0 50px;
    }

    font-size: 18px;
    letter-spacing: 0;
    padding: 45px 0 0 25px;
  }
}

.scoreRowContainer {
  display: flex;
  justify-content: space-between;
  background-color: $otcWhite;
  padding: 20px 50px 20px 35px;
  color: $otcBlack;
  margin-top: 15px;
  margin-bottom: 30px;
  box-sizing: border-box;
  
  @include  sm {
    margin: 15px 0;
  }

  &:last-child {
    margin: 0;
  }

  .score {
    @include sm {
      font-size: 14px;
    }

    font-weight: $fontWeightExtraBold;
    font-size: 24px;
  }
}

.sectionRiskCategory {
  @include lg {
    @include pageWidth;
    padding-top: 125px;
    padding-bottom: 210px;
    box-sizing: border-box;

    .desc {
      letter-spacing: 1px;
    }
  }
  
  padding: 25px 25px 75px;

  h1 {
    @include lg {
      font-size: 50px;
      margin-bottom: 45px;
    }
    font-size: 28px;
    margin-bottom: 15px;
  }

  .desc {
    letter-spacing: 1px;
  }

  .categoryList {
    @include lg {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      margin-top: 65px;
    }

    @include md {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin-top: 45px;
    }

    .categoryLabel {
      @include sm {
        margin: 10px 0;
        font-size: 18px;
        height: 45px;
      }

      display: flex;
      align-items: center;
      background-color: $otcWhite;
      color: $otcBlack;
      font-size: 22px;
      font-weight: $fontWeightBold;
      padding: 12px;
    }
  }
}

.sectionInterested {
  text-align: center;
  margin-bottom: 140px;

  h1 {
    @include lg {
      font-size: 68px;
      margin-bottom: 50px;
    }

    @include md {
      font-size: 50px;
      margin-bottom: 35px;
    }

    font-size: 28px;
    margin-bottom: 25px;
    font-weight: $fontWeightExtraBold;
  }
}