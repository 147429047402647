@import '../../global.scss';

.container {
  text-align: center;
  width: 350px;
  margin: 0 auto;
}

h3.formTitle {
  text-transform: none;
}

.formTitle, .formDesc {
  margin: 0;
}

.formList {
  margin: 0;
  padding-left: 20px;
  font-size: 12px;
}

.greeting {
  margin-bottom: 15px;
  text-align: left;
}

.form {
  background-color: $otcWhite;
  margin-top: 10px;
  border: 1px solid $otcLightGray;
  border-radius: 5px;
  padding: 30px;
}

label {
  display: block;
}

.input {
  margin-bottom: 20px;
}

.submit {
  margin: 10px 0;
}

.error {
  display: block;
  color: red;
}

.banner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: $otcGrey;
  padding: 10px;
  border-radius: 5px;
  color: white;

  .closeBtn {
    cursor: pointer;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}