@import '../../global.scss';

.MAClosingSummaryPage {
  p {
    margin: 0;
  }
}

.tradeDataTable {
  position: relative;
  margin-bottom: 3em;
}

.overviewLeft {
  margin-right: 1em;
}

.overviewRight {
  margin-left: 1em;
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

.tableTotals {
  text-align: center;
  margin-top: 1em;
  font-size: 0.8em;
  font-weight: $fontWeightLight;
}

.footerNote {
  font-size: 0.7em;
  font-weight: $fontWeightLight;
  color: $otcGray;
  display: block;
  margin-bottom: 3em;
}

.sideColumn {
  @include sideColumn;
}

@include md {
  .footerNote {
    margin-bottom: 4em;
  }
}
