@import '../../global.scss';

.component {
  top: 10px;
}

.sticky {
  position: fixed;

  @include sm-md  {
    position: relative;
  }
}