@import '../../global.scss';

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.input {
  text-align: center;
  font-family: $otcFontPrimary;
  font-weight: $fontWeightBold;
}