@import '../../global.scss';

.header {
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  margin-bottom: 5px;
  cursor: pointer;
}
.chevron {
  display: inline-block;
  font-size: .8em;
  color: #999;
  margin-top: 2px;
}
.body {
  padding-top: 2px;
  padding-bottom: 6px;
  margin-bottom: 5px;
  border-bottom: 1px solid #aaa;
}

