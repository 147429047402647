@import '../../../global.scss';

.item {
  .content {
    font-family: $otcFontSecondary;
    color: $otcDarkGray;
  }

  .firstColumn {
    color: $otcDarkGray;
    flex: 1 1 0;
  }

  .lastColumn {
    color: $otcGray;
    font-size: 1.0em;
    text-align: right;

    a {
      margin-right: 0.5em;
      color: $otcGray;
    }
  }
  svg {
    position: relative;
    bottom: .1em;
    margin-left: .6em;
    color: $otcGray;
  }

  .rightValue {
    text-align: left;
  }
}

.link {
  text-decoration: underline !important;
  word-break: break-all
}
