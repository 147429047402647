@import "../../global.scss";

.videoContainer {
    position: relative;
    padding-top: 56.25%;

    .video {
        position: absolute;
        top: 0;
        left: 0;
    }
}
