@import '../../global.scss';

.filters {
  margin-bottom: 2em;
  border-top: 3px solid $otcGreen;
}

.table {
  margin-bottom: 3em;
}

.sideColumn {
  @include sideColumn;
}