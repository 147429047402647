@import '../../global.scss';

$gutterSize: 20px;

.quoteInfo {
  margin-left: -$gutterSize;
  width: calc(100% + (#{$gutterSize} * 2));
  margin-bottom: 2.5em;

  label {
    font-size: 0.7em;
    font-weight: $fontWeightLight;
    color: $otcGray;
    text-transform: uppercase;

    svg {
      vertical-align: middle;
      position: relative;
      font-size: .85rem;
      left: .2em;
      bottom: .1em;
    }
  }

  p {
    font-weight: bold;
    border-bottom: 1px solid $otcLightGray;
    padding-bottom: .1em;
  }

  .quoteInfoItem {
    padding: .5em $gutterSize;
  }
}

