@import '../../global.scss';

.tabBarContainer {
  position: relative;
}

.tabBar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  border-top: 1px solid $otcLightGray !important;
  @include lg {
    margin-top: -14px;
    border: none !important;
  }

  > div, > a {
    display: inline-block;
    color: $otcGray;
    text-decoration: none;
    font-size: .9em;
    padding: .6em .9em;

    @include md {
      padding: .68em 1em;
    }
    @include lg {
      padding: .7em 1em;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
  > div:first-child, > a:first-child {
    padding-left: 0;
  }
  > a:hover {
    color: $otcGreen;
  }
}

.overflowMenuButton {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  padding: 0 .4em;
  padding-top: .1em;
  transition: color 150ms;
  cursor: pointer;
  color: $otcGray;
  &:hover {
    color: $otcGreen;
  }
}

.overflowMenu {
  position: absolute;
  box-sizing: border-box;
  top: 100%;
  right: 0;
  border: 1px solid $otcGreen;
  width: 300px;
  background: #fff;
  z-index: 200;
  > div, > a {
    color: $otcGray;
    text-decoration: none;
    padding: .38em .6em;
    display: block;
    border-bottom: 1px solid $otcLightGray;
    &:last-child {
      border-bottom: none;
    }
  }
  @include sm-md {
    /* accounts for thicker border */
    margin-top: 2px;
  }
  @include sm {
    width: 100%;
  }
  /* arrow */
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    right: 12px;
    width: 12px;
    height: 12px;
    background: #fff;
    border-right:1px solid $otcGreen;
    border-bottom:1px solid $otcGreen;
    transform:rotate(-135deg);
  }

  &.orange {
    border-color: $otcOrange;
    &::after {
      border-color: $otcOrange;
    }
  }

  &.pink {
    border-color: $otcPink;
    &::after {
      border-color: $otcPink;
    }
  }
}

.activeTab {
  font-weight: bold;
}
