@import '../../global.scss';

.light {
  font-weight: 100;
}

$lineHeight: 1.5em;

.richContent {
  line-height: $lineHeight;
  p, ol, ul {
    margin: 0;
  }
  li {
    margin-bottom: .2em;
  }
}

.lineBreak {
  min-height: 1em;
}

/* Text Styles */
.caption { @include caption; }
.serif { font-family: $otcFontSecondary !important; }

/* Text Colors */
.colorGreen { color: $otcGreen; }
.colorOrange { color: $otcOrange; }
.colorPink { color: $otcPink; }

/* Horizontal Line */
.hr {
  background: $otcLightGray;
  border: none;
  height: 1px;
  margin: .7em 0 .7em 0;
}
// remove padding if horizontal line is next to line break
.hr + .lineBreak { margin-top: -.7em; }
.lineBreak + .hr { margin-top: 0; }

/* Content table styles */
.tableContainer {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  border-top: 1px solid $otcGray;
  margin-bottom: .5em;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: -.75rem;
  }
  @include lg {
    &:first-child {
      border-top: none;
    }
  }
}
.table {
  font-size: 1em;
  tr:first-child td {
    padding-top: .8em;
    padding-bottom: .78em;
  }
  td {
    font-weight: normal;

    > * {
      min-height: auto;
    }
  }
}

/* Hide grid cell content if empty */
.gridCell {
  > *:empty:only-child, .lineBreak:only-child {
    display: none;
  }
}
