@import '../../global.scss';

// @include link-colors;

.linkTo {
  width: 100%;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  svg { margin-right: .2em; }
}

.link {
  font-weight: $fontWeightBold;
  line-height: 1em;
  border-bottom: 1px solid $otcLightGray;
  padding: 1em 0;
  width: 100%;
  position: relative;
}

.linkAngle {
  position: absolute;
  right: 0;
}

.storyLinks {
  border-top: 1px solid $otcLightGray;
  margin: 1em 0;
  padding: 1em 0;
}

.social {
  display: inline;
  color: $otcGray;
  font-size: 1.2em;
  letter-spacing: 1em;

  a {
    margin-right: 0.5em;
    color: $otcGray;
  }
}

.storyHeading {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: $fontWeightBold;
  padding: 0.9em 0 1em 0;

  .storyInfo {
    font-size: 0.8em;
    margin-left: 2em;
    font-weight: normal;
    color: $otcGray;
  }
}

.largeContent {
  width: 100%;
  overflow-x: scroll;
  * {
    font-family: $otcFontSecondary !important;
    color: $otcDarkGray;
    font-weight: lighter;
    border: none;
    word-wrap: break-word;
  }
  h1 {
    font-size: 1.8em;
    font-weight: bold;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: 1em;
  }
  h2, h3, h4 {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: 1em;
  }
  p {
    margin-bottom: 1em !important;
  }
  p, div, span {
    &:empty {
      display: none;
    }
  }
  i {
    font-style: italic !important;
  }
  b, strong {
    font-weight: bold !important;
  }
}

.videoDescription {
  margin-top: 1em;
  font-size: 1em;
  line-height: 1.2em;
  color: $otcGray;
}

.videos {
  margin-bottom: 3em;
  .videoContainer {
    margin-top: 1em;
    margin-left: -20px;
    width: calc(100% + (20px * 2));
  }
  .video {
    padding: 0 20px;
    padding-bottom: 1em;
  }
}
