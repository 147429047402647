@import '../../global.scss';

$triangleSize: 8px;
$offset: 2rem;
$childColor: #9EA0A2;

.BankAccordian {
  .inside {
    display: none;

    &.show {
      display: block;
    }
  }
}
.BankDetail {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 5px;
  border-bottom: 1px solid $otcLightGray;
  align-items: center;
  min-height: 44px;
  color: $childColor;

  @include sm {
    padding: 10px 0;
  }

  @include md-lg {
    grid-template-columns: 5.5fr 2.5fr;
  }

  &.hasChildren {
    cursor: pointer;
  }

  &.parent {
    color: initial;
  }

  .accordian {
    position: absolute;
    display: inline-flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;

    @include sm {
      font-size: .75em;
    }
  }

  .companyInfo {
    display: flex;
    align-items: center;

    @include sm {
      justify-content: space-between;
    }

    .name {
      display: inline-block;
      font-size: 16px;
      line-height: 20px;

      @include md-lg {
        max-width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 15px;
      }

      &.parent {
        font-weight: bold;
      }
    }
  
    .location {
      display: grid;
      position: relative;
      color: #939597;
      font-weight: lighter;
      padding-left: 10px;
      align-items: center;

      @include lg {
        grid-template-columns: 1fr 7fr;
      }

      .marker {
        font-size: 1.1em;
        fill: #F35C5C;
      }

      .text {
        font-size: 0.7rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .companyType {
    position: relative;
    display: grid;
    grid-template-columns: 7fr 1fr;
    grid-column-gap: 8px;

    @include sm {
      margin-top: 10px;
    }

    &.noPercent {
      grid-template-columns: 1fr;
    }

    .type {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include md-lg {
        text-align: right;
        margin: 0 !important;
      }
    }

    .percentage {
      display: inline-flex;
      position: relative;
      background-color: #D8D8D8;
      width: 47px;
      height: 23px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      justify-self: end;
      border-radius: 8px;
      
      .percentageBar {
        position: absolute;
        display: block;
        background-color: #71B9FD;
        border-radius: 8px;
        height: 100%;
        top: 0;
        left: 0;
      }

      .text {
        z-index: 1;
        color: $otcWhite;
      }
    }
  }

  .tooltip {
    position: absolute;
    opacity: 0;
    top: 35px;
    background-color: $otcWhite;
    border: 1px solid $otcLightGray;
    padding: .65rem .85rem;
    z-index: 0;
    visibility: hidden;
    font-size: 0.875rem;
    font-weight: normal;
    min-width: 175px;
    transition: opacity 0.2s,
      visibility 0.2s,
      z-index 0.2s;

    &.tooltipRight {
      top: 25px;
      right: 0;

      .triangle {
        left: auto;
        right: 0;
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }

    .triangle {
      content: '';
      position: absolute;
      width: $triangleSize;
      height: $triangleSize;
      background: white;
      border-left: 1px solid $otcLightGray;
      border-top: 1px solid $otcLightGray;
      top: -5px;
      left: 15px;
      transform:
        translateX(-50%)
        rotate(45deg);
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.04);
    }
  }
}