@import '../../global.scss';

.component {

}

.header {
  font-weight: bold;
  padding: .4em 0;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid $otcLightGray;
}

.headerIcon {
  margin-left: auto;
  font-size: .75rem;
  display: flex;
  align-items: center;
  color: lighten($otcDarkGray, 20%);
}

.isOpen .headerIcon {
  transform: rotate(180deg);
}

.option {
  padding: .5em 1.5em;
  border-bottom: 1px solid #E9E9E9;
  color: lighten($otcDarkGray, 10%);
  cursor: pointer;
}

.selectedOption {
  background-color: $otcGreen;
  color: white;
}
