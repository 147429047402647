@import '../../global.scss';

.help-links {
  margin-top: 3em;
  margin-left: .75em;
}

.help-title {
  font-weight: bold;
  margin-bottom: .5em;
  color: $otcDarkGray;
  text-transform: capitalize;
}

.link {
  display: flex;
  color: $otcDarkGray;
  margin-bottom: .35em;
  align-items: center;
  text-decoration: none;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.linkLabel {
  display: inline;
}

.linkIcon {
  margin-left: .7em;
  color: $otcGreen;
}