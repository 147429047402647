@import '../../global.scss';

.info {
  border-top: $dividerMedium;
  padding: 1em 0;
  p {
    margin: 0;
    font-family: $otcFontSecondary;
  }
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

.footerNote {
  font-size: 0.7em;
  font-weight: $fontWeightLight;
  color: $otcGray;
  display: block;
  margin-top: 2em;
  margin-bottom: 1em;
}

@include md {
  .footerNote {
    margin-bottom: 4em;
  }
}
