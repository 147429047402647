@import '../../global.scss';

.container {
  position: relative;
  margin-bottom: 45px;
  font-weight: $fontWeightBold;
  @include sideColumn;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: .5em; 
}

.link {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $otcGreen;
  }
}

.viewLink {
  margin-top: 45px;
}