@import '../../global.scss';

.CompanyQuoteDisclosure {
  .otcDisclosureTable {
    position: relative;
  }
}

.left {
  padding-right: 1.2em;
}
.right {
  padding-left: 1.2em;
}
.left, .right {
  @include sm {
    padding: 0;
  }
}
.transactionsHeader {
  border-bottom: 1px solid $otcLightGrey;
  font-weight: bold;
  padding-top: .5em;
  padding-bottom: .4em;
}
.transactionBody {
  border-bottom: 1px solid $otcLightGrey;
  font-size: .9rem;
}
.transactionItem {
  padding-top: .4em;
  padding-bottom: .3em;
}
.transactionItemHeader {
  font-weight: bold;
  color: $otcGrey;
  text-transform: uppercase;
  font-size: .8rem;
  margin-bottom: .1em;
}
.footerNote {
  font-size: 0.7em;
  font-weight: $fontWeightLight;
  color: $otcGray;
  margin-top: 1.5em;
  display: block;
}