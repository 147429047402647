.container{
    display: flex;
    justify-content: flex-end;

    .tick {
        font-size: 1.3em;
        position: relative;
        bottom: .1em;
      }
  
    .price{
      flex-basis: 60px;
    }
  }
