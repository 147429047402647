@import '../../global.scss';

.container {
  white-space: nowrap;
  position: relative;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.hasIcon {
    padding-left: 2.4em;
  }
}

.tierIcon {
  position: absolute;
  top: 0;
  bottom: .1em;
  left: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  img {
    max-height: 1.3em;
    max-width: 1.5em;
  }
}

.large {
  @include lg {
    font-size: 24px;

    &.hasIcon {
      padding-left: 3.5em;
    }

    .tierIcon {
      img {
        max-height: 1.3em;
        max-width: 2em;
      }
    }
  }

  @include md {
    font-size: 24px;

    &.hasIcon {
      padding-left: 3.5em;
    }

    .tierIcon {
      img {
        max-height: 1.3em;
        max-width: 2em;
      }
    }
  }
}