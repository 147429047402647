@import '../../global.scss';

$triangleSize: 8px;
$offset: 2rem;

.tooltipContainer {
  position: relative;
  cursor: default;
}

.tooltip {
  position: absolute;
  font-size: 0.85em;
  text-align: left;
  background: white;
  border: 1px solid $otcLightGray;
  min-width: 10rem;
  max-width: 20rem;
  color: $otcDarkGray;
  padding: .65rem .85rem;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s,
    visibility 0.2s,
    z-index 0.2s;

  .title {
    font-size: 1rem;
    font-weight: bold;
  }
  .content {
    font-size: 0.875rem;
    font-weight: normal;
  }
  .divider {
    height: 0;
    margin: .5rem 0;
    border-bottom: 1px solid $otcLightGray;
  }

  &.tooltipOpen {
    visibility: visible;
    opacity: 1;
    z-index: 100;
  }

  .triangle {
    content: '';
    position: absolute;
    width: $triangleSize;
    height: $triangleSize;
    background: white;
    border-left: 1px solid $otcLightGray;
    border-top: 1px solid $otcLightGray;
  }

  &.top {
    .triangle {
      bottom: -$triangleSize * 0.7;
      left: $offset;
      transform:
        translateX(-50%)
        rotate(225deg);
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.04);
    }
  }

  &.bottom {
    .triangle {
      top: -$triangleSize * 0.6;
      left: $offset;
      transform:
        translateX(-50%)
        rotate(45deg);
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.04);
    }
  }

  &.left {
    .triangle {
      top: $offset;
      right: -$triangleSize * 1.2;
      transform:
        translateX(-50%)
        rotate(135deg);
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.04);
    }
  }

  &.right {
    .triangle {
      top: $offset;
      left: -$triangleSize * 0.1;
      transform:
        translateX(-50%)
        rotate(-45deg);
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.04);
    }
  }
}
