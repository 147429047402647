@import '../../global.scss';

.component {
  position: relative;
  padding: 0 15px 22px;
  
  &.icon {
    padding: 0 0 10px;

    .dropdown {
      right: -14px;
    }
  }
}

.profileIcon {
  width: 30px;
  text-align: right;
  
  svg {
    padding-bottom: 10px;
  }
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  font-weight: $fontWeightBold;

  span {
    line-height: 12px;
  }
}


.chevron {
  display: inline-block;
  font-size: .8em;
  margin-left: 15px;
  transform-origin: center 7.5px;
  
  &.chevronUp {
    transform: rotate(180deg);
    transform-origin: center 7.5px;
  }
}
.dropdown {
  position: absolute;
  min-width: 250px;
  right: 0;
  top: 36px;
  background-color: $otcWhite;
  color: $otcDarkGrey;
  font-weight: $fontWeightSemiBold;
  box-shadow: 0px 5px 10px 1px rgba(0,0,0,0.22);

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    border: 7px solid transparent;
    border-bottom-color: $otcWhite;
    left: auto;
    right: 15px;
    top: -14px;
  }
  
  .link {
    display: block;
    padding: 5px 20px;
    border-bottom: 1px solid $otcLightGray;
    z-index: 10;

    &:hover {
      background-color: $otcLightGray;
    }

    &:last-child {
      border: none;
    }
  }
}
