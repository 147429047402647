@import '../../global.scss';

$infoTextFontSize: 0.9em;

.companyQuotePage {
  p {
    margin: 0;
  }
}

.header, .bid {
  h2 {
    margin: 0;
  }
}

.companyName {
  font-size: 1.3em;
  position: relative;
  top: -.5em;
  &:empty::after { content: ' '; white-space: pre; }
}

.profile {
  margin-top: .45em;

  .tierSection {
    padding-top: .28em;
  }

  .tierText {
    font-size: $infoTextFontSize;
    padding-bottom: .5em;

    @include sm {
      border-top: 1.5px solid $otcLightGray;
      padding-top: .5em;
      padding-bottom: 0em;
    }
  }

  .informationOutline {
    padding-top: 0;
    border-top: none;
    font-size: 1em !important;
  }

  .infoText {
    font-size: $infoTextFontSize;
    display: block;
    width: 100%;
    text-decoration: none;
    line-height: 1.6em;

    @include sm {
      margin-bottom: .5em;
    }
  }

  img {
    height: 1.7rem;
    margin-bottom: .2em;
  
    &:only-child {
      margin-bottom: -.25em;
    }

    &.award {
      height: 4.1rem;
    }
  }
}

.indexBoxContainer {
  margin-top:.6em;

  .indexBox {
    border: 1.5px solid $otcGreen;
    text-decoration: none;
    padding: .5em;
    margin-top: .4em;
    display: flex;
    justify-content: space-between;
    
    @include md {
      margin-top: .4em;
    }

    .indexLink {
      font-weight: bold;
      text-decoration: none;
    }
  }

  .green {
    border: 1.5px solid $otcGreen;
  }

  .orange {
    border: 1.5px solid $otcOrange;
  }

  .green:hover {
    color: $otcGreen;

    .indexLink {
      color: $otcGreen;
    }
  }

  .orange:hover {
    color: $otcOrange;

    .indexLink {
      color: $otcOrange;
    }
  }

  @include md {
    border-top: 1.5px solid $otcLightGray;
    margin-top: 0em;
    padding-top: .7em;
  }
}

.flags {
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 0px;

  .icon {
    display: inline-flex;
    color: $otcGray;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    margin-right: .65em;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    float: left;
    position: relative;
    bottom: .1em;
  }

  .flagsContent > div {
    margin: .6em 0;
  }

  .flagsText {
    display: inline-block;
    text-decoration: none;
    line-height: 1em;
    height: 1em;
    color: $otcBlack;
  }

  .subtext {
    font-weight: 100;
    padding-left: .4em;
  }

  .flagsGrid {
    flex-direction: column;
  }
}

.greenFlag {
  &:hover {
    .flagsText {
      color: $otcBadgeGreen;
    }
  }
}

.yellowFlag {
  &:hover {
    .flagsText {
      color: $otcBadgeYellow;
    }
  }
}

.redFlag {
  &:hover {
    .flagsText {
      color: $otcBadgeRed;
    }
  }
}

@include lg {
  .tierSection {
    border-bottom: none
  }
}

@include md {
  .tierSection {
    border-bottom: none;
  }

  .leftColumnProfile {
    margin-top: 2em;
    padding-bottom: .5em;
    border-top: 1px solid $otcLightGray;
    position: relative;

    &:after {
      content: '';
      border-right: 1px solid $otcLightGray;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .informationOutline {
      border-top: none;
      border-bottom: none;
      margin: 0;
      padding: 0;
      font-size: .9em;
    }
  }
  .rightColumnFlags {
    margin-top: 2em;
    padding: 0.5em 0;
    border-top: 1px solid $otcLightGray;

    .ratingsOutline {
      border-top: none;
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
  }
  .leftColumnFirst {
    padding-right: 3em;
    padding-top: 3em;
  }
  .rightColumnFirst {
    padding-left: 3em;
    padding-top: 3em;
  }
  .leftColumn {
    padding-right: 3em;
  }
  .rightColumn {
    padding-left: 3em;
  }
}

@include sm {
  .profile {
    margin-top: 1em;
    padding-top: .8em;
    border-top: 1px solid $otcLightGray;
    position: relative;

    .informationOutline {
      border-top: none;
      border-bottom: none;
      margin: 0;
      padding: 0;
      font-size: .9em;
    }
  }
  .flags {
    margin-top: .5em;

    .ratingsOutline {
      border-top: 1px solid $otcLightGray;
      border-bottom: none;
      margin: 0;
      padding-bottom: 1em;
      padding-top: .7em;
    }
    .flagsLeft {
      border-top: 1px solid $otcLightGray;
      margin-bottom: -1px;
    }
  }

  .ask {
    padding: .3em 0 .2em 0 !important;
  }
  .indices {
    display: none;
  }
}

.flagsContent {
  @include lg {
    padding-top: .35em;
    padding-bottom: .2em;
    border-bottom: 1px solid $otcLightGray;

    &:empty {
      display: none;
    }
  }
}

@include md-lg {
  .bid {
    text-align: right;
  }
}

@include sm {
  .bid {
    padding-top: .5em;
    border-top: $dividerGrey;
    margin-top: 1em;
  }
}

.bidline {
  svg {
    @include h2;
    transform: translate(0, .3em);
    height: .8em;
    width: .75em;
    margin-right: -.2em;
  }
}

.ask {
  font-size: .9em;
  line-height: 1.8em;
  position: relative;
  top: -.5em;
  padding-bottom: .5em;
}

.subHeader {
  @include caption;
  font-weight: 300;
  position: relative;
  line-height: 1.2em;
  &:empty::after { content: ' '; white-space: pre; }
  * { line-height: 1.2em; }
}

.evo {
  @include h2;
  padding-top: .28em;
  margin-left: .2em;
  p {
    font-size: .36em;
    line-height: 1.25em;
  }
}

.positive {
  svg, p {
    color: $otcGreen;
  }
  svg {
    margin-top: -.02em;
  }
}

.negative {
  svg, p {
    color: $otcErrorColor;
  }
}
.noChange {
  svg, p {
    color: $otcGray;
    margin-left: .2em;
  }
}


.ratingsOutline {
  border-bottom: 1px solid $otcLightGray;
  padding-top: .8em;
  padding-bottom: 1em;
  margin-bottom: 1em;
  position: relative;
}

.detailsOutline {
  border-top: 1px solid $otcLightGray;
  padding: 0.5em 0;
  margin-top: .5em;
}

.rating {
  display: inline-block;
  font-size: 1.3em;
  margin-left: 0.4em;
}

.securities, .advancers {
  b {
    padding-top: 0.6em;
    display: inline-block;
  }

  p {
    margin: 0.6em 0;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding: 0;
    margin: 0.6em 0;
  }

  li {
    font-weight: lighter;
  }

  margin-bottom: 2.5em;
  border-bottom: 1px solid $otcLightGray;
}
.advancers {
  li {
    padding: .2em 0;
    span {
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      vertical-align: top;
    }
  }
}

.messages {
  border: 8px solid $otcLightGray;
  padding: 0.7em;
  margin-bottom: 3em;
  color: $otcErrorColor;
  font-weight: bold;
  font-size: 0.9em;
}

.suspendedStatus {
  color: $otcErrorColor;
  font-weight: normal;
}

.totalContainer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: $fontWeightBold;

  &.green {
    color: $otcGreen;
  }

  &.orange {
    color: $otcOrange;
  }

  &.pink {
    color: $otcPink;
  }

  &.gray {
    color: $otcGray;
  }

  .total {
    font-size: 2.25em;
    border-width: 3px;
    border-style: solid;
    border-radius: 50% 50%;
    display: flex;
    width: 115px;
    height: 115px;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
  }
}
