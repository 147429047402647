@import '../../global.scss';

.tableFilters {
  vertical-align: top;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.filterItem {
  margin: 0 1em !important;
  &:first-child {
    margin-left: 0 !important;
  }
  &:last-child {
    margin-right: 0 !important;
  }
}

.filterToggle {
  display: none;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $otcLightGray;
  padding: 0.5em 0;
  cursor: pointer;
  width: 100%;
}

@include md-lg {
  .TableFilter {
    font-size: .85em;
  }
}

@include sm {
  .TableFilter {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
  }
  .filterItem {
    margin: 1em 0 0 0 !important;
  }
  .tableFilters {
    display: none;
    &.showFilters {
      display: block;
      position: relative;
    }
  }
  .filterToggle {
    display: flex;
  }
}
