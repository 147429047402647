@import '../../../global.scss';

.item {
  width: 100%;
  font-size: 0.9em;

  .field {
    padding-bottom: .1em;
    word-break: break-all;

    a:hover {
      color: unset !important;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
