@import '../../global.scss';

.component {
  p {
    margin: 0;
  }
}

.description {
  font-family: $otcFontSecondary;
  color: $otcDarkGray;
  font-weight: lighter;
  padding-bottom: 3em;
  border-top: 3px solid $otcGreen;
  padding-top: .8em;
}

.table {
  position: relative;
  margin-bottom: 3em;
}

.tierIcon {
  font-weight: bold;
  img {
    width: 24px;
  }
}

.sideColumn {
  @include sideColumn;
}