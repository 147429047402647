@import '../../global.scss';

.MAComplianceStatsPage {
  p {
    margin: 0;
  }

  abbr {
    color: $otcGray;
    font-size: 0.8em;
    padding: 1em 0;
    display: block;

    img {
      height: 4em;
      margin-right: 1em;
    }
  }
}

.tradeDataTable {
  position: relative;
  padding-top: 2em;
  margin-bottom: 3em;
}

.tables > div {
  &:first-child {
    .tradeDataTable {
      padding-top: 0;
    }
  }

  &:last-child {
    .tradeDataTable {
      margin-bottom: 5em;
    }
  }
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

.info {
  border-top: $dividerMedium;
  padding: 1em 0;
  p {
    margin: 0;
    font-family: $otcFontSecondary;
  }
}

@include lg {
  .main, .headerContainer {
    padding-left: 3em;

    .links {
      position: absolute;
      bottom: 0em;
    }
  }
}
