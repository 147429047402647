@import '../../global.scss';

.DashboardPage {
  margin-top: 25px;
}

.table {
  position: relative;
  margin-bottom: 3em;
}

@include lg {
  .main {
    flex-direction: column;
  }

  .side {
    flex-direction: column;
    padding-left: $sidebarMargin;
  }
}

.advertBanner {
  margin: 1.2em auto;
  @include sm {
    display: none;
  }
}

.footerNote {
  font-size: 0.8em;
  font-weight: $fontWeightLight;
  color: $otcGray;
  margin-top: 1em;
  display: block;
}

.footerLink {
  font-size: .88rem;
  display: flex;
  align-items: center;
  
  a {
    text-decoration: none;
    &:hover { text-decoration: underline; }
  }
  svg {
    color: $otcGreen;
    margin-left: .4em;
  }
}

.marketActivityLink {
  margin-top: .6em;
  
  @include sm {
    display: none;
  }
}

.newsLink {
  border-bottom: 1px solid $otcLightGray;
}

.columnLeft {
  padding-right: 1.5em;
}
.columnRight {
  padding-left: 1.5em;
}

.mainBanner {
  width: 100%;
  background: $otcGreen;
  margin-bottom: 2.2em;
}

.newsTable, .featuredNewsItem {
  a {
    text-decoration: none !important;
  }
}

.featuredNewsItem {
  width: 50%;
  display: inline-block;
  box-sizing: border-box;
  padding-right: 1em;
  vertical-align: top;
  margin-bottom: 2.5em;
  &:nth-child(2n) {
    padding-left: 1em;
    padding-right: 0 !important;
  }
  @include sm {
    padding: 0 !important;
    width: 100%;
  }
}

.featuredNewsItemImage {
  width: 100%;
  padding-bottom: 50%;
  background-color: $otcLightGrey;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.featuredNewsItemHeadline {
  color: $otcDarkGray;
  display: inline-block;
  text-decoration: none;
  margin-top: .4em;
  font-weight: bold;
  &:hover { text-decoration: underline; }
}

.featuredNewsDescription {
  color: $otcDarkGray;
  display: inline-block;
  margin-top: .3em;
  font-size: .9em;
}

.newlyTrading {
  margin-top: 3em;
  margin-bottom: 3em;
  @include sm {
    margin-bottom: 0;
  }
  border: 8px solid $otcLightGray;
  padding: 0.7em;

  .newlyTradingTitle {
    font-size: 1.4em;
    font-weight: bolder;
    text-transform: uppercase;
  }

  h4 {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
  }

  p {
    font-size: 0.9em;
    color: $otcGray;
  }

  a {
    @include button;
    font-weight: bold;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    margin-top: 1.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    svg {
      font-size: 1.3em;
      margin-left: .2em;
      margin-bottom: .1em;
    }
  }
}

.newlyTradingLogos {
  margin: .5em 0;
  border-top: 1px solid $otcLightGray;
  border-bottom: 1px solid $otcLightGray;
  padding: .8em 0;
  display: flex;
  align-items: center;

  > div {
    width: 50%;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;

    &:first-child {
      border-right: 1px solid $otcLightGray;
    }
    &:last-child {
      justify-content: flex-end;
    }

    img {
      width: 86%;
    }
  }
}

.carousel {
  position: relative;

  img {
    width: 100%;
  }

  li {
    background: transparent !important;
  }
}

.carouselPlaceholder {
  width: 100%;
  padding-top: 56%;
  background: $otcLightGrey;
}

.carouselInner {
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.carouselLink {
  color: #fff;
  font-size: 2em;
  padding: 1em 1.5em !important;
  text-align: left;
  position: absolute;
  line-height: 1.2em;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  text-decoration: none;
  align-items: flex-end;
  &:hover {
    color: #fff;
  }

  @include sm {
    font-size: 1.4em;
  }
  @include md-lg {
    padding-right: 7em !important;
  }

  &.hasText {
    /* gradient background */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 45%,rgba(0,0,0,0.6) 100%);
  }
}

.carouselPrevArrow,
.carouselNextArrow {
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  z-index: 1;
  font-size: 1.8em;
  color: rgba($otcWhite, 0.5);
  text-align: center;
  padding: 0;
  border: none;
  outline: none;

  &:hover {
    transition: all .25s ease-in;
    background: rgba(0, 0, 0, 0.4);
  }
}

.carouselPrevArrow {
  left: 0;
}

.carouselNextArrow {
  right: 0;
}

.videoTitle {
  color: $otcDarkGray;
  font-size: 1.06em;
  margin-top: 1em;
  line-height: 1.1em;
}
