@import '../../global.scss';

.montage {
  position: relative;
  margin-bottom: 3em;
}

.unlock {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($otcWhite, 0.75);

  .unlockContent {
    position: relative;
    text-align: center;
    background-color: $otcWhite;
    border: 2px solid $otcLightGray;
    width: 12em;
    max-width: 80%;
    margin-top: 4.5em;
    font-weight: bold;
    padding: 2em 6em;
    box-shadow: 0 0 10px #fff;

    a {
      color: #000;
      display: block;
      border: 2px solid $otcPink;
      border-radius: 0;
      background: $otcWhite;
      text-transform: uppercase;
      text-decoration: none;
      margin-top: 2em;
      width: 100%;
      line-height: 2.4em;
      font-weight: bold;
      padding: 0;
    }
  }
}

.tableSwitch {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  text-transform: uppercase;
  padding: 0.5em;
  border-bottom: 1px solid $otcLightGray;
  cursor: pointer;

  &.active {
    border-bottom: 1px solid $otcDarkGray;
    color: $otcDarkGray;
  }

  @include md-lg {
    display: none;
  }
}

.tableHidden {
  @include sm {
    display: none;
  }
}
