@import '../../global.scss';

.CompanyQuoteSecurity {
  h4 {
    font-size: 1.13em;
    border-bottom: 1px solid $otcLightGray;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
  }

  .corpActionsHeader {
    padding-bottom: 0.5em;
    border-bottom: 1px solid $otcLightGray;
    h4 {
      padding: 0;
      margin: 0;
      border: none;
    }
  }

  abbr {
    color: $otcGray;
    font-size: 0.75em;
    display: inline-block;
    font-weight: 200;
    margin-top: .5rem;
  }

  .icon {
    color: $otcGray;
    margin-left: 0.5em;
    margin-top: -.22em;
  }

  .row {
    border-bottom: 1px solid $otcLightGray;
    padding-top: 1.0em;
    padding-bottom: 0.5em;
    font-size: 0.9em;

    b {
      font-weight: $fontWeightBold;
    }

    &.child {
      padding-top: 0;
      padding-bottom: 0.5em;
      color: $otcGray;
      b {
        font-weight: 500;
      }
    }

    &.hideUnderline {
      border-bottom: none;
    }
  }

  &.green {
    a {
      @include green-link;
    }
  }

  &.orange {
    a {
      @include orange-link;
    }
  }

  &.pink {
    a {
      @include pink-link;
    }
  }

  &.gray {
    a {
      @include gray-link;
    }
  }

  &.black {
    a {
      @include black-link;
    }
  }
}

.dividendsSplits {
  position: relative;
  padding-top: 1.1em;
  margin-top: 3em;
}
