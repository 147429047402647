@import "../../global.scss";

.accordion {
  &:first-child {
    margin-top: -.75rem;
  }
}

.noResults {
  color: $otcGrey;
  font-style: italic;
}
