@import '../../global.scss';

.newsWidget {
  border-top: 1px solid $otcLightGray;
  margin-top: .6em;
  &:first-child {
    margin-top: -.75rem;
    border-top: none;
  }
}
