@import '../../global.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  z-index: 10000;
  display: none;

  &.show {
    display: block;
  }
}

.modal {
  position: relative;
  border: 2px solid $otcGreen;
  margin: 15vh auto;
  max-width: 95vw;
  box-sizing: border-box;
  padding: 1.2em;
  background-color: $otcWhite;
  box-shadow: 0 0 1px rgba(0,0,0,.5), 0 0 10px rgba(0,0,0,.05);
  color: initial !important;

  .closeButton {
    position: absolute;
    right: -0.75em;
    top: -0.75em;
    border: 2px solid $otcWhite;
    background-color: $otcDarkGray;
    color: $otcWhite;
    cursor: pointer;
    text-decoration: none;
    width: 1.5em;
    height: 1.5em;
    font-size: 1em;
    line-height: 1.4em;
    text-align: center;
    border-radius: 2em;
    display: none;

    &.show {
      display: block;
    }
  }


  &.green {
    border-color: $otcGreen;
  }

  &.pink {
    border-color: $otcPink;
  }

  &.orange {
    border-color: $otcOrange;
  }

  &.gray {
    border-color: $otcGray;
  }

  &.black {
    border-color: #000;
  }
}

.link {
  @include link;
}
