@import '../../global.scss';

.component {
  p {
    margin: 0;
  }
}

.typeSelect {
  border-top: 3px solid $otcGreen;
  margin-bottom: 2em;
}

.table {
  position: relative;
  margin-bottom: 3em;
}

.headingCount {
  font-size: 1em;
  font-weight: bold;
}

.otherDirectories {
  margin-top: 1.7em;
}

.sideColumn {
  @include sideColumn;
}