@import '../../../global.scss';

.item {
    flex-direction: column;
    .field {
      padding-bottom: .1em;
      word-break: break-all;
  
      a:hover {
        color: unset !important;
      }
  
      &:last-child {
        padding-bottom: 0;
      }
    }

}

.link {
  text-decoration: underline !important;
  word-break: break-all;
}


