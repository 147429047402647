@import '../../global.scss';

.component {
  p {
    margin: 0;
  }
}

.desc {
  border-top: 3px solid $otcGreen;
  font-family: "Source Serif Pro", "Georgia", serif;
  color: #444444;
  font-weight: lighter;
  padding: .8em 0;
}

.table {
  position: relative;
  margin-bottom: 3em;
  @include sm-md {
    border-top: none;
  }
}

.abbr {
  color: $otcGray;
  font-size: 0.8em;
  padding: 1em 0;
  display: block;
}
