@import '../../global.scss';

.info {
  padding: 1em 0;
  p {
    margin: 0;
    font-family: $otcFontSecondary;
  }
}

.filter {
  border-top: $dividerMedium;
  margin-bottom: 1em;
}
.filterLeft {
  margin-right: 1em;
}
.filterRight {
  margin-left: 1em;
}

.mdSubscription {
  margin-right: 3em;
}

.mdAdvert {
  margin-left: 3em;
}

.sideColumn {
  @include sideColumn;
}