@import '../../global.scss';

.MABrokerDealerPage {
  p {
    margin: 0;
  }
}

.back {
  margin-bottom: 0.5em;
  color: $otcGray;
  font-weight: $fontWeightLight;
  line-height: 1.34em;
  font-weight: 0.8em;
  text-decoration: none;
  svg {
    margin-right: 0.25em;
  }
}

.tradeDataTable {
  position: relative;
  margin-bottom: 3em;
}

.abbr {
  color: $otcGray;
  font-size: 0.8em;
  padding: 1em 0;
  display: block;
}

.overviewLeft {
  margin-right: 1em;
}

.overviewRight {
  margin-left: 1em;
}

.hideOnMobile {
  display: none;
  
  @include lg {
    display: block;
  }  
}

.sideColumn {
  @include sideColumn;
}
