$size: 30px;
$margin: $size / 1.4;
$shadow: 0px 2px 4px 0px #333333;

.component {
  position: fixed;
  left: 50%;
  margin-left: -($size / 2);
  background-color: #cccccc;
  border-radius: 50%;
  width: $size;
  height: $size;
  display: inline-block;
  bottom: $margin;
  box-shadow: $shadow;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.95) translateY(-10px);
  will-change: opacity, transform;
  transition: 200ms opacity, 120ms transform;
  z-index: 9999;
}

.isLocked {
  position: absolute !important;
  top: -$margin;
}

.isVisible {
  transform: none;
  opacity: 1;
  pointer-events: all;
}

.icon {
  color: #ffffff;
  width: 2em;
  height: 2em;
  transform: translate(-.05em,-.25em);
}
