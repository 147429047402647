@import '../../global.scss';

.contact {
  height: 100vh;
  background-color: $otcWhite;
  padding-top: 50px;

  h1 {
    font-weight: bold;
    margin-bottom: 1em;
    color: $otcGreen;
  }
}

.form {
  background-color: $otcWhite;
  margin: 10px auto 0;
  border: 1px solid $otcLightGray;
  border-radius: 5px;
  padding: 30px;
}

h3.formTitle {
  text-transform: none;
}

.formTitle, .formDesc {
  margin: 0;
}

.formList {
  margin: 0;
  padding-left: 20px;
  font-size: 12px;
}

.greeting {
  margin-bottom: 15px;
  text-align: left;
}

.modalBody {
  text-align: center;
}

.success {
  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 1.2rem;
  }
  @include sm-md {
    text-align: center;
  }
}
