@import '../../global.scss';

.dateContainer {
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    width: 100%;
  }
}

.box {
  display: flex;
  padding: 10px;
  align-items: baseline;
  width: 100%;
  border: 2px solid #000;
  text-align: left;
  font-size: 16px;
  height: 44px;
  background-color: $otcWhite;

  &.green {
    border-color: $otcGreen;

    .calIcon {
      fill: $otcGreen;
    }

    .caretIcon {
      fill: $otcGreen;
    }
  }

  &.orange {
    border-color: $otcOrange;

    .calIcon {
      fill: $otcOrange;
    }

    .caretIcon {
      fill: $otcOrange;
    }
  }

  &.pink {
    border-color: $otcPink;

    .calIcon {
      fill: $otcPink;
    }

    .caretIcon {
      fill: $otcPink;
    }
  }

  &.gray {
    border-color: $otcGray;

    .calIcon {
      fill: $otcGray;
    }

    .caretIcon {
      fill: $otcGray;
    }
  }

  &.black {
    border-color: #000;

    .calIcon {
      fill: #000;
    }

    .caretIcon {
      fill: #000;
    }
  }

  &:focus {
    outline: none;
  }

  .calIcon {
    fill: #000;
    padding-right: 10px;
  }

  .caretIcon {
    fill: #000;
    margin-left: auto;
  }
}

.small {
  font-size: 11px;
}

.label {
  position: absolute;
  font-family: $otcFontPrimary;
  font-size: .8em;
  transform: translate3d(0, -2em, 0);
  font-weight: $fontWeightSemiBold;
  color: $otcGrey;
  z-index: 10;

  &.small {
    font-size: 11px;
  }

  &.active {
    color: $otcGreen;
  }
}

.inputNote {
  font-size: .8em;
  position: absolute;
  left: 0;
  bottom: 0;
}

.descMessage {
  color: $otcGray;
}

.errorMessage {
  color: $otcErrorColor;
  animation: fadein 0.5s;

  @keyframes fadein {
    from { top: 2.2em; opacity: 0; }
    to   { top: 2.8em; opacity: 1; }
  }
}