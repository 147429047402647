@import '../../global.scss';

.scrollIndicators {
  display: none;
  
  @include sm-md {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    padding: 0 !important;
  }
}

.featherEdge {
  display: block;
  width: 15px;
  height: 40px;
  background: -moz-linear-gradient(left, rgba(255,255,255,1) 52%, rgba(255,255,255,1) 53%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255,255,255,1) 52%,rgba(255,255,255,1) 53%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255,255,255,1) 52%,rgba(255,255,255,1) 53%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
  padding: 0 !important;
  border: none !important;

  &:last-child {
    transform: scaleX(-1);
  }
}

@mixin activeBorder {
  @include sm-md {
    border-bottom-width: 2px;
  }
}
.companyQuoteMenu {
  margin: 1.3em 0 3em 0;

  @include sm-md {
    margin: 1.3em 0 2em 0;
    
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  
  div {
    font-size: .8em;
    border-top: $dividerGrey;
    border-bottom:$dividerGrey;
    padding: .8em 0;
    text-align: center;

    @include sm-md {
      padding: .8em 1.2em;
    }

    &.active {
      &.green {
        border-bottom: 1px solid $otcGreen;
        @include activeBorder;
      }

      &.orange {
        border-bottom: 1px solid $otcOrange;
        @include activeBorder;
      }

      &.pink {
        border-bottom: 1px solid $otcPink;
        @include activeBorder;
      }

      &.gray {
        border-bottom: 1px solid $otcGray;
        @include activeBorder;
      }

      &.black {
        border-bottom: 1px solid #000;
        @include activeBorder;
      }
    }
    a {
      text-decoration: none;
      color: $otcDarkGray;
      font-weight: bold;
    }
  }
}

.mobileMenu {
  margin-bottom: .6em;
  border-top: $dividerGrey;
  border-bottom: $dividerGrey;

  @include lg {
    display: none !important;
  }

  span {
    padding-left: .6em;
  }

  .mobileMenuItem {
    padding-left: .6em;
    font-size: .9em;
    line-height: .9em;
    font-weight: bold;
    text-align: center;
    border-bottom: $dividerGrey;
    padding-bottom: 0.5em;
    padding-top: 0.5em;

    &:last-child {
      border-bottom: none;
    }

    a {
      text-decoration: none;
      color: $otcDarkGray;
      width: 100%;
    }

    &.active {
      a {
        padding-bottom: 0.5em;
        border-bottom: 2px solid $otcGreen;
      }

      &.green, &.default {
        a {
          border-bottom: 2px solid $otcGreen;
        }
      }

      &.orange {
        a {
          border-bottom: 2px solid $otcOrange;
        }
      }

      &.pink {
        a {
          border-bottom: 2px solid $otcPink;
        }
      }

      &.gray {
        a {
          border-bottom: 2px solid $otcGray;
        }
      }

      &.black {
        a {
          border-bottom: 2px solid #000;
        }
      }
    }
  }

  .mobileMenuContainer {
    overflow: hidden;
    max-height: 0;
  }

  .mobileMenuOpen {
    max-height: $maxHeightFull;
  }

  .mobileMenuHeader {
    cursor: pointer;
    border-bottom: none;

    .mobileMenuBarsIcon {
      display: inline;
      margin-bottom: 0.2em;
    }

    .mobileMenuCloseIcon {
      display: none;
      margin-bottom: 0.2em;
    }
  }

  .mobileMenuHeaderOpen {
    border-bottom: 1px solid $otcLightGray;

    .mobileMenuBarsIcon {
      display: none;
    }

    .mobileMenuCloseIcon {
      display: inline;
    }
  }
}

.tab {
  position: relative;
}

.newFlag {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: .65em;
  font-weight: 700;
  color: $otcGreen;

  &.fixedIncomeOnlyFlag {
    right: auto;
  }
}