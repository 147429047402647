@import "../../global.scss";

.inline {
  display: inline-block;
}

.hideSM {
  @include sm {
    display: none;
  }
}

.hideMD {
  @include md {
    display: none;
  }
}

.hideLG {
  @include lg {
    display: none;
  }
}
