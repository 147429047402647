@import '../../global.scss';

.component {
  p {
    margin: 0;
  }
}

.table {
  position: relative;
  margin-bottom: 3em;
  @include sm-md {
    border-top: none;
  }
}

h2 {
  margin: 0;
}

.menu {
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
  border-bottom: 1px solid $otcGreen;
  padding: 0;
  margin: 0;
  padding-bottom: .75em; 
  border-color: #000;
  border-width: 2px;

  &.green {
    border-color: $otcGreen; 
  }

  &.pink {
    border-color: $otcPink; 
  }

  &.orange {
    border-color: $otcOrange;
  }

  &.gray {
    border-color: $otcGray;
  }
}

.dateFitler {
  display: flex;
}