@import '../../global.scss';

.MAIndexPage {
  p {
    margin: 0;
  }
}

.header, .bid {
  margin-top: .5em;
  border-bottom: 1px solid $otcLightGray;
  padding-bottom: 1em;
  h2 {
    margin: 0;
  }
}

.companyName {
  font-size: 1.3em;
  position: relative;
  top: -.5em;
}

$gutterSize: 20px;

.info {
  margin-top: .5em;
  margin-bottom: 2em;
  margin-left: -$gutterSize;
  width: calc(100% + (#{$gutterSize} * 2));

  label {
    font-size: 0.7em;
    font-weight: $fontWeightLight;
    color: $otcGray;
    text-transform: uppercase;
  }

  p {
    font-weight: bold;
    border-bottom: 1px solid $otcLightGray;
    padding-bottom: .1em;
  }

  .quoteInfoItem {
    padding-top: 1em;
    padding: .5em $gutterSize;
  }
}

.constituents {
  position: relative;
  @include sm-md {
    margin-bottom: 3em;
  }
}

.download {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em 0;
}

.rules {
  margin: 3em 0;
  p {
    margin: 1em 0;
  }
}

.mdLeft {
  margin-right: 3em;
}

.mdRight {
  margin-left: 3em;
  height: fit-content;
}

.linkList {
  padding: 1em 0;
  a {
    color: black;
  }
  border-bottom: 1px solid $otcLightGray;
}

@include md-lg {
  .bid {
    text-align: right;
  }
}

@include sm {
  .bid {
    margin-top: 1em;
  }
}

.bidline {
  svg {
    @include h2;
    transform: translate(0, .3em);
    height: .8em;
    width: .75em;
    margin-right: -.2em;
    margin-left: .1em;
  }
}

.subHeader {
  @include caption;
  font-weight: 300;
  position: relative;
  line-height: 1.2em;
  &:empty::after { content: ' '; white-space: pre; }
  * { line-height: 1.2em; }
}

.evo {
  @include h2;
  padding-top: .28em;
  margin-left: .2em;
  p {
    font-size: .36em;
    line-height: 1.25em;
  }
}

.positive {
  svg, p {
    color: $otcGreen;
  }
  svg {
    margin-top: -.02em;
  }
}

.negative {
  svg, p {
    color: $otcErrorColor;
  }
}

.backLink {
  border-bottom: 1px solid $otcLightGray;
  margin-top: 2em;
  @include md {
    margin-top: 1.5em;
  }
  @include sm {
    margin-top: 1em;
  }
}
