@import '../../../global.scss';

.item {
  padding-top: .5em;
  padding-bottom: .5em;
  width: 100%;

  .content {
    font-family: $otcFontSecondary;
    color: $otcDarkGray;
    font-weight: lighter;

    @include sm {
      padding-left: 0;
    }
  }

  .socialButtonsContainer {
    margin-top: 1em;

    @include md-lg {
      display: none;
    }
  }

  .socialButton {
    color: $otcGray;
    font-size: 1.0em;
    text-align: right;
    margin-right: 1em;
  }
}
