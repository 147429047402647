@import '../../global.scss';

.title {
  border-bottom: 1px solid $otcGreen;
  font-weight: bold;
  margin-bottom: .8em;
  font-size: 1.4em;
  margin-top: 0;
  padding-bottom: .2em;
  &.orange {
    border-bottom: 1px solid $otcOrange;
  }
  &.pink {
    border-bottom: 1px solid $otcPink;
  }
}

.form {
  margin: 0 -1em;
  margin-top: 2.8em;
}

.inputWrapper {
  padding: 0 1em;
  > div {
    margin-top: 0px !important;
  }
}

.message {
  h3 {
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: none;
    margin-bottom: .1em;
  }
  p { font-size: 1rem; }
  @include sm-md {
    text-align: center;
  }
}

.buttonWrapper {
  text-align: center;
  margin-bottom: 1em;
}

.contactModal {
  button {
    @include button;
    @include sm {
      width: 100%;
      text-align: center;
      box-sizing: border-box;
    }

    &.orange {
      color: $otcOrange;
      border-color: $otcOrange;
      &:hover { color: #fff; background: $otcOrange; }
    }
    &.pink {
      color: $otcPink;
      border-color: $otcPink;
      &:hover { color: #fff; background: $otcPink; }
    }
  }
}
button.submit {
  width: 10em;
}

.textarea {
  border: 1px solid $otcGreen;
  display: block;
  width: 100%;
  height: 6em;
  margin: 1.3em 0 2em 0;
}
