@import '../../global.scss';

.details {
  margin-bottom: 3em;
  padding-top: .8em;
  border-top: 3px solid $otcGreen;
}

.filtersWrapper {
  border-top: 1px solid $otcGreen;
  padding-top: .5em;
  padding-bottom: 2em;
}
.filters {
  > * {
    padding: 0 20px;
  }
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

// Details

.companyName {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: .8em;
}

.addressWrapper {
  @include md {
    border-top: 1px solid $otcLightGray;
    border-bottom: 1px solid $otcLightGray;
  }
}

.address {
  color: $otcDarkGray;
  padding: .8em 0;
  @include sm {
    border-top: 1px solid $otcLightGray;
  }
  @include lg {
    border-top: 1px solid $otcLightGray;
  }
  @include md {
    display: inline-block;
    border-right: 1px solid $otcLightGray;
    border-left: 1px solid $otcLightGray;
    padding: .8em 1.4em;
    margin-right: -1px;
    vertical-align:top;
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
}


